<template>
    <div class="page">
      <div class="header">
        <h1>{{ title }}</h1>
      </div>

      <div class="body">
        <div class="sidebar">
          <ul>
            <li
            v-for="item in menuItems"
            :key="item.label"
            :class="{ active: selectedMenu === item.key }"
            @click="selectMenu(item)"
          >
            <i :class="item.icon"></i> 
            <span>{{ item.label }}</span>
          </li>
          </ul>
        </div>
  
        <div class="content">
          <div v-if="selectedMenu === 'sent'" class="dinamic">
            <show-emails :data="dataExampleEnviado"></show-emails>
          </div>
          <div v-else-if="selectedMenu === 'important'" class="dinamic">
            <show-emails :data="dataExampleEnviado"></show-emails>
          </div>
          <div v-else-if="selectedMenu === 'drafts'" class="dinamic">
            <show-emails :data="dataExampleDraft" dateColumn="Fecha de Creación"></show-emails>
          </div>
          <div v-else-if="selectedMenu === 'starred'" class="dinamic">
            <show-emails :data="dataExampleStarred"></show-emails>
          </div>
          <div v-else-if="selectedMenu === 'new'" class="dinamic">
            <send-email></send-email>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
    import ShowEmails from '../components/notifications/ShowEmails.vue';
    import SendEmail from '../components/notifications/SendEmail.vue';

    export default {
      components: { ShowEmails,SendEmail },
      data() {
        return {
         
          dataExampleEnviado: [
            {
              id: 1,
              asunto: "",
              para: "santygarces@gmail.com",
              enviado: "15-11-2023 10:33:15 hs",
              
            },
            {
              id: 2,
              asunto: "uso de mail profesional",
              para: "pepe@gmail.com",
              enviado: "15-12-2023 10:33:15 hs",
             
            },
            {
              id: 3,
              asunto: "skynet lo sabe",
              para: "terminator@gmail.com",
              enviado: "2024-11-18",
              
            },
          ], 
          dataExampleStarred: [
            {
              id: 1,
              asunto: "test de email",
              para: "juan@gmail.com",
              enviado: "15-11-2023 10:33:15 hs",
            },
            {
              id: 2,
              asunto: "COMO SE SACAN LAS MAYUSCULAS???",
              para: "ROBERTO@GMAIL.COM",
              enviado: "15-12-2023 10:33:15 hs",
            },
            {
              id: 3,
              asunto: "hasta la vista baby",
              para: "terminator@gmail.com",
              enviado: "2024-11-18",
            },
          ], 
          dataExampleDraft: [
            {
              id: 1,
              asunto: "lista de compras",
              para: "verdulero@gmail.com",
              enviado: "15-11-2023 10:33:15 hs",
              creationDate: "15-11-2024 10:33:15 hs"
            },
            {
              id: 2,
              asunto: "renuncia programada",
              para: "RRH",
              enviado: "15-12-2023 10:33:15 hs",
              creationDate: "15-11-2024 10:33:15 hs"
            },
            {
              id: 3,
              asunto: "hasta la vista baby",
              para: "terminator@gmail.com",
              enviado: "2024-11-18",
              creationDate: "15-11-2024 10:33:15 hs"
            },
          ], 
  
          title: 'Gestión de correos Enviados',
          selectedMenu: 'sent',
          menuItems: [
            { icon:"pi pi-file mr-2", label: 'Nuevo Correo', key: 'new' },
            { icon:"pi pi-send mr-2", label: 'Gestión de correos Enviado', key: 'sent' },
            { icon:"pi pi-star mr-2", label: 'Mensajes destacados', key: 'starred' },
            { icon:"pi pi-bookmark mr-2", label: 'Mensajes Importantes', key: 'important' },
            { icon:"pi pi-trash mr-2", label: 'Borradores', key: 'drafts' },
          ],
        };
      },

      methods: {
        selectMenu(item) {
          this.selectedMenu = item.key;
          this.title = `${item.label}`;
        },
  
      },

    };
  </script>
  
  <style scoped>
  .header {
    padding: 10px 20px;
    text-align:  start; 
  }
  .header h1 {
    margin: 0;
    font-size: 28px;
  }
  .body {
    display: flex;
    flex: 1;
    overflow: hidden;
  }

  .sidebar {
    padding: 0px;
    display: flex;
    width: 320px;
    background-color: #fff;
    border: 1px solid #CED4DA;
    border-radius: 5px;
    height: 340px;
  }
  
  .sidebar ul {
    list-style: none;
    padding: 0px;
    margin: 0;
    width:100%;
  }
  
  .sidebar li {
    display: flex; 
    align-items: center; 
    justify-content: flex-start; 
    padding: 0px 15px; 
    cursor: pointer;
    height: 68px;
    width: 100%;
    transition: background-color 0.3s, font-weight 0.3s; 
    position: relative;
  }
  
  .sidebar li i {
    position: absolute;
    left: 15px;
  }
  
  .sidebar li span {
    margin: 0 auto;
  }
  
  .sidebar li:hover {
    background-color: #E3F2FD; 
  }
  
  .sidebar li.active {
    background-color: #E3F2FD;
    
  }
  
  .content {
    flex: 1;
    padding: 0px 20px;
    overflow-y: auto; 
  }

  .dinamic{
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  </style>
  