import Vue from 'vue'
import axios from 'axios'
import { QueryClient } from '@tanstack/vue-query';

const queryClient = new QueryClient();

const state = {
  allrows:0,
  indices:[],//arreglo con los numeros de las paginas es para la paginacion
  list: [],
  depSelected:null,
  oficiales:[],
  oficialesModal:[],
  filters: {},
  order: null,
  loading: true,
  search: null,
  lastUrl:"",
  filterField: null,
  limit : 8,//numero maximo de actuaciones que se van a traer con el get
  offset : 0,//Indice de la posicion desde la cual se empezaran a traer las Actuaciones
  tablasListadas: 0 ,//Este numero reprecenta la cantidad de tablas que se listaron por ultima vez
  contPag: 0,//Lo utilizamos para saber en que numero de pagina nos encontramos
  userId: ""
}

// actions
const actions = {
  updateOffset({commit},value){
    commit("updateOffset",value)
  },
  async setSumariosSinSecretario({commit}, DependenciaID){
    // Ahora con los Usuario de Clase "Juzgado" esta funcion no siempre se llevara a cabo
    if(DependenciaID){
      let url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/actuacion-usuario-dependencia/`;
      let ids = (await axios.get(url, { withCredentials: true, params: {method : "own", DependenciaID: DependenciaID} })).data
      // console.log("ids>>>>", ids)
      // SETEA LA INFO PARA QUE SEA MAS FACIL DE LEER Y FILTRA LOS SUMARIOS QUE YA ESTAN EN LA LISTA PARA NO BUSCARLOS
      ids= ids.data.map(e=>e.ActuacionID).filter(e=>!state.list.find(sumario=>e===sumario.ActuacionID))  
      const result =(await axios.get(`${process.env.VUE_APP_BACKEND_ENVIRONMENT}/sumario?search={"exp_pre_Actuacion.ActuacionID":[{"operator":"IN","value":${JSON.stringify(ids)}}]}`,{ withCredentials: true })).data
  
      commit("setSumariosSinSecretario",result.data)
    }
  },
  setDepSelected({commit},payload){
    commit("setDepSelected",payload)
  },
  async setOrder({commit},payload){
    await commit("setOrder",payload);
    return Promise.resolve()
  },
  deleteActuaciones({ commit }, payload)
  {
    commit('deleteActuaciones',payload)
    // commit('resetActuaciones');
  },
  loadingUpdate({ commit }, payload)
  {
    commit('loadingUpdate', payload)
  },

  async resetActuaciones({ commit }) {
    commit('resetActuaciones');
  },

  // realiza el GET a la API
  async get ({ commit, state }, payload) {
    try {
      // console.log("state.allrows al empezar get: ",state.allrows)

      /*
      * total: la cantidad de resultados que devuelve la api
      * si ya se sabe la cantidad de resultados que devuelve la api (state.allrows) usa ese;
      * sino establece 0
      * luego si el valor es cero hara una consulta para saber el total de registros
      */
      let total = state.allrows == 0 ? 0 : state.allrows
      let data = []
      let allrows = 0;
      // console.log("tablasListadas",state.tablasListadas," accion: ", payload.accion)

      //maneja las paginas
      if(payload &&(payload.accion == "inicio" || (payload.accion == "anterior" ||payload.accion == "anteriorx2" &&  state.offset != 0) || ( payload.accion == "siguiente" ||payload.accion == "siguientex2" && state.tablasListadas == 8 )))
      {
        commit('updateOffset',  payload)

      }

      // pone el spinner de que esta cargando
      commit('loadingUpdate', true)

      // si el modo offline no esta activado hace el requerimiento a la api online
      if (Vue.prototype.$offline.onlineMode){

        // setea la url
        let url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/sumario`;

        // prepara los parametros para $rest
        let getOptions = {
          url,
          params: {
            limit: state.limit,
            offset : state.offset,
            getTotal : total == 0 ? true : false
          }
        }
        
        // si hay filtros establecidos en state.search los agrega
        // console.log("state.search", state.search)
        if (state.search && state.search != "{}"){
          getOptions.params.search = state.search;
        }

        // si establecio un orden especifico
        if (state.order){
          getOptions.params.order = state.order;
        }
        // console.log("loadingUpdate getOptions", getOptions)

        // hace la consulta
        const r = await Vue.prototype.$rest.get(getOptions)
        // datos devueltos
        total = r.data.total
        data =  r.data.data;
        allrows = r.data.allrows
        // console.log("r: ",r.data.total)
        // console.log("Buscando el allrows: ",r.data)
        // console.log(data)

        // carga actuaciones desde es SERVIDOR OFFLINE
        // si esta offline y es la primer pagina y no es una busqueda
        // estas son actuaciones que no se ha subido aun al servidor online
        if (Vue.prototype.$offline.offlineServer && state.offset === 0 && !getOptions.params.search){

          // hace consulta a servidor offline
          const rOff = await Vue.prototype.$offline.getItem('actuaciones')
          // console.log("rOff.data", rOff.data)

          // las ordena por fecha de inicio
          rOff.data.data.sort((a, b) => {
            if(a.Sumario.Fechainicio > b.Sumario.Fechainicio) return -1
            if (a.Sumario.Fechainicio < b.Sumario.Fechainicio) return 1
            return 0
          } )
          
          //suma los resultados al total
          total = total + rOff.data.total

          //agrega las actuaciones a la lista de actuaciones online (las pone al principio)
          data =  [...rOff.data.data, ...data];
        }

        commit('setLastUrl', getOptions)
      } else {
        // AQUI ES MODO OFFLINE

        // carga actuaciones desde es SERVIDOR OFFLINE
        const r = await Vue.prototype.$offline.getItem('actuaciones')
        
        // carga el total
        total = r.data.total

        // las ordena por fecha de inicio
        r.data.data.sort((a, b) => {
          if(a.Sumario.Fechainicio > b.Sumario.Fechainicio) return -1
          if (a.Sumario.Fechainicio < b.Sumario.Fechainicio) return 1
          return 0
        } )

        // prepara las listas
        data =  r.data.data;
        allrows =r.data.allrows

      }

      //actualiza state
      if(allrows > -1){
        commit('setTotal', allrows ? allrows : 0)
      }
      commit('TablasListadas', total)
      commit('contPag')
      commit('setList', data)
    }
    catch(e){
      console.log("error sumario", e)
    }

  },
  setSearch({ commit }, payload){
    commit('setSearch', payload)
  },
  setFilterField({ commit }, payload){
    commit('setFilterField', payload)
  },
  updateUserId({ commit }, payload){
    commit('updateUserId', payload)
  },
}

const getters = {
  getPagination(){
    let actualPage = state.offset/state.limit
    // console.log("state.allrows: ", state.allrows )
    let cantidad = Math.ceil(state.allrows/state.limit)
    let indices = state.indices.slice((actualPage > 2 ? actualPage-2 : 0 ),(actualPage < cantidad-2 ? (actualPage > 1 ? actualPage+3 : 5 ): state.indices[state.indices.length-1]))
    return {
      indices,
      limit: state.limit,
      offset: state.offset,
      actualPage
    }
  },
  getTotal(){
    return state.allrows;
  },
  getLastUrl(){
    return state.lastUrl;
  },
  getOficiales(){
    return state.oficiales
  },
  getOficialesModal(){
    return state.oficialesModal
  },
  getList(){
    let list
      list = JSON.parse(JSON.stringify(state.list)).sort((x,y)=>{
        if (x.ActuacionID.search("offline-") === 0) return -1
        if (!Array.isArray(x.ActuacionUsuarioDependencia)) {
          return -1
        } else {

          if ( x.ActuacionUsuarioDependencia.find(
            e=>e.UsuarioID==="Sin Usuario Asignado" && e.Fechahasta === null || e.RecienEnviada == "1"
          )) return -1
          if (y.ActuacionID.search("offline-") === 0) return 1
          if ( y.ActuacionUsuarioDependencia.find(
              e=>e.UsuarioID==="Sin Usuario Asignado" && e.Fechahasta === null || e.RecienEnviada == "1"
          )) return 1
          return 0
        }
      })
      list.sort((x,y)=>{
        if (x.ActuacionID.search("offline-") === 0) return -1
        if(!x.Numero)
        return -1
        if(y.Numero)
        return 1
        return 0
      })


    //agrega nombre de denunciante y juzgado al sumario esto es para que el sort de la tabla pueda funcionar
    let newData= list.map(e=>{
      if (e.ActuacionID.search("offline-") === 0) {
        // console.log("e>>", e)
        e.ActuacionPersonaFisica = { Damnificados: []}
        for ( let i in e.Denunciante) {
          if (e.Denunciante[0].Rol == "Damnificado") {
            e.ActuacionPersonaFisica?.Damnificados.push(e.Denunciante[1])
          }
        }
        
        return {...e,
          nombreDenunciante: (e.ActuacionPersonaFisica?.Damnificados[0] ? e.ActuacionPersonaFisica.Damnificados[0].Apellido : ""),
          nombreJuzgado: (e.Juzgadointerviniente ? e.Juzgadointerviniente.Nombre : "" ),
          EstadoSumario: null,
          SumarioNumero: e.Sumario.Numero,
          ActuacionUsuarioDependencia: []
        }
      }

      let nombreDenunciante
      if (e.ActuacionPersonaFisica["AFECTADO"] != undefined) { 
        const roles = ["Denunciante y Damnificado","Denunciante","Victima","Fallecido","Protagonista","Damnificado"];
        let indice = -1;
        roles.some(rol => {
            indice = e.ActuacionPersonaFisica["AFECTADO"].findIndex(elemento => elemento.TipoRolPersonaFisicaID === rol);
            return indice !== -1;
        });
        if (indice !== -1) {
          const afectado = e.ActuacionPersonaFisica["AFECTADO"][indice];
          nombreDenunciante = afectado.Apellido || '';
          if (afectado.Nombre) {
              nombreDenunciante += `, ${afectado.Nombre}`;
          }
        } 
      } 
      return {...e,
        nombreDenunciante,
        // nombreDenunciante: (e.ActuacionPersonaFisica["Denunciantes"] ? e.ActuacionPersonaFisica?.Denunciantes[0].Apellido : (
        //   e.ActuacionPersonaFisica["Victima"]  ? e.ActuacionPersonaFisica?.Victima[0].Apellido : ""
        // )),
        nombreJuzgado: (e.Roljuzgadosumario ? e.Roljuzgadosumario?.Nombre : "" ),
        EstadoSumario: e.EstadoActuacion.find(estado=>estado.FechaHasta==null)?.TipoEstadoActuacionID
      }
    })

    return newData
  }
}
// mutations
const mutations = {
  setTotal(state,total){
    let cantidad = Math.ceil(total/state.limit)
    let indices = []
    for(let i=0; i<cantidad;i++){
        indices.push(i+1)
    }
    state.indices = indices
    state.allrows = total;
  },
  setLastUrl(state, { params, url }){
    let attrs = Object.getOwnPropertyNames(params)
    attrs.map((e,i)=>{
      if(i==0){
        url+=`?${e}=${params[e]}&`
      }else if(i!==attrs.length-1){
        url += `${e}=${params[e]}&`
      }else{
        url += `${e}=${params[e]}`
      }
    })
    state.lastUrl = url
  },
  setSumariosSinSecretario(state,payload){
    payload?.map(e=>{

      state.list.unshift(e)
    })
  },
  setDepSelected(state,payload){
    state.depSelected=payload;
  },
  setOrder(state,payload){
    //! no me pregunten porque , pero este console.log hace que funcionen los test de filtro por fecha(Cypress), no eliminar ;)
    // console.log(payload)
    state.order=payload;
    return Promise.resolve()
  },
  setOficiales(state,payload){
    state.oficialesModal=payload.oficialesModal;
    state.oficiales=payload.oficiales;
  },
  deleteActuaciones(state, payload)
  {
    let indice = state.list.findIndex( element => element.ActuacionID == payload)
    state.list.splice(indice, 1);
    state.loading = false;
  },
  resetActuaciones(state)
  {
    state.list= [];
    state.filters= {};
    state.loading= true;
    state.search= "";
    state.filterField= null;
    state.offset = 0;
    state.tablasListadas= 0 ;
    state.contPag= 0;
    // state.allrows = 0;

    state.userId = null; //
    state.allrows = 0;// una vez se limpian los filtros, se pone allrows en 0 para que en el siguiente llamado a la Api, se solicite el total nuevamente (esto es para actualizar la paginacion)
    state.depSelected = null;
    state.rangoFechas = null;


    // state.depSelected = null,
    // state.rangoFechas = null,
    // state.oficiales = [],
    // state.oficialesModal = [],



    // state.lastUrl = "",


    // state.tablasListadas =  0 ,//Este numero reprecenta la cantidad de tablas que se listaron por ultima vez
    // state.contPag =  0,//Lo utilizamos para saber en que numero de pagina nos encontramos
    // state.userId =  ""
  },
  contPag(state)
  { state.contPag++ },
  TablasListadas(state, payload)
  {
    state.tablasListadas = payload
  },
  updateOffset(state, payload)
  {
    if(!payload.accion)
    {
      state.offset = payload;
    }else
    if(payload.accion == "inicio"){
      state.offset = 0;
    }else
    if(payload.accion == "siguiente")
    {
      state.offset = state.offset + state.limit
    }else
    if(payload.accion == "siguientex2")
    {
      state.offset = (Math.ceil(state.allrows/state.limit)-1)*state.limit
    }else
    if(payload.accion == "anterior")
    {
      state.offset = state.offset != 0 ? state.offset - state.limit  : 0
    }else
    if(payload.accion == "anteriorx2")
    {
      state.offset = 0
    }
  },
  setList (state, payload) {
    state.list = payload;
    state.loading = false;
  },
  setSearch (state, payload) {
    state.search = payload;
  },
  setFilterField (state, payload) {
    state.filterField = payload;
  },
  loadingUpdate (state, payload)
  {
    state.loading = payload
  },
  updateUserId(state, payload)
  {
    state.userId = payload
  }
}


export default {
  // emptyObject,
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
