<template>
  <div>
    <div>
      <h2>Solicitudes</h2>
    </div>
    <DataTable :value="solicitudes" :loading="loadingTable">
      <template #loading> Cargando datos. </template>
      <template #empty>
        <div class="text-center" v-if="!message">
          <h4>No existen registros para mostrar</h4>
        </div>
        <div v-if="message" class="text-center">
          <h4>No se ha podido cargar la tabla. Por favor intente mas tarde</h4>
        </div>
      </template>
      <Column field="createdAt">
        <template #header>
          <div class="flex align-items-center" :test-id="'divColumHeaderTableSumario'">
            Fecha
            <Button
              :icon="'pi pi-sort-alt'"
              class="p-button-rounded p-button-secondary p-button-text"
            />
          </div>
        </template>
      </Column>
      <Column field="nombre">
        <template #header>
          <div class="flex align-items-center" :test-id="'divColumHeaderTableSumario'">
            Nombre
            <Button
              :icon="'pi pi-sort-alt'"
              class="p-button-rounded p-button-secondary p-button-text"
            />
          </div>
        </template>
      </Column>
      <Column field="apellido">
        <template #header>
          <div class="flex align-items-center" :test-id="'divColumHeaderTableSumario'">
            Apellido
            <Button
              :icon="'pi pi-sort-alt'"
              class="p-button-rounded p-button-secondary p-button-text"
            />
          </div>
        </template>
      </Column>
      <Column field="dni">
        <template #header>
          <div class="flex align-items-center" :test-id="'divColumHeaderTableSumario'">
            DNI
            <Button
              :icon="'pi pi-sort-alt'"
              class="p-button-rounded p-button-secondary p-button-text"
            />
          </div>
        </template>
      </Column>
      <Column field="celular">
        <template #header>
          <div class="flex align-items-center" :test-id="'divColumHeaderTableSumario'">
            Celular
            <Button
              :icon="'pi pi-sort-alt'"
              class="p-button-rounded p-button-secondary p-button-text"
            />
          </div>
        </template>
      </Column>
      <Column field="Email">
        <template #header>
          <div class="flex align-items-center" :test-id="'divColumHeaderTableSumario'">
            Email
            <Button
              :icon="'pi pi-sort-alt'"
              class="p-button-rounded p-button-secondary p-button-text"
            />
          </div>
        </template>
      </Column>
      <Column header="Acción">
        <template #body="slotProps">
          <div>
            <Button
              type="button"
              v-tooltip.left="'Ver esta solicitud'"
              icon="pi pi-eye"
              class="p-button-rounded p-button-outlined"
              test-id="button-leer-solicitud"
              @click="viewSolicitud(slotProps.data.formulario_depositoID)"
            ></Button>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>
<script setup>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import { ref, watch, defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router/composables";
import { useRest } from "@/composables/useRest.js";
const { get } = useRest();

const Props = defineProps({
  efectoID: String,
});

const loadingTable = ref(false);
const message = ref(false);
const dataSolicitudes = ref(null);
const solicitudes = ref(null);
const url = process.env.VUE_APP_BACKEND_ENVIRONMENT + "/formulario-deposito";
const router = useRouter();

onMounted(async () => {
  await getSolicitudes();
  filterSolicitudes();
});

const getSolicitudes = async () => {
  loadingTable.value = true;

  await get(url)
    .then((response) => {
      loadingTable.value = false;
      dataSolicitudes.value = response.data.data;
    })
    .catch((error) => {
      message.value = true;
      loadingTable.value = false;
    });
};

const filterSolicitudes = () => {
  solicitudes.value = dataSolicitudes.value.filter((obj) => obj.id_deposito === Props.efectoID);
};

const viewSolicitud = (id) => {
  router.push({
    path: "/deposito/solicitudes/" + id + "?view=true",       
  });

};
</script>
