import Vue from 'vue'
import Vuex from 'vuex'
import routes from './modules/routes'
import notifications from './modules/notifications'
import siis from './modules/siis'
import user from './modules/user'
import utils from './modules/utils'
import sumario from './modules/sumario'
import sumarioViews from './modules/sumario/views'
import editMode from './modules/sumario/editMode'


// import editorWord from './modules/editorWord'

import sumarioList from './modules/sumarioList'
import sipe from './modules/external/sipe'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

 const app= new Vuex.Store({
  modules: {
    siis,
    routes,
    user,
    notifications,
    sumario,
    sumarioList,
    sipe,
    utils,
    sumarioViews,
    editMode,
    // editorWord
  },
  test: ()=> {
    console.log("hola mundo>>>>>")
  },
  strict: debug,
  // plugins: debug ? [createLogger()] : []
})
window.app=app
export default app
