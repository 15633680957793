import { useQuery } from '@tanstack/vue-query';
import Vue from 'vue';
import { QueryClient } from '@tanstack/vue-query';

const queryClient = new QueryClient();

export default {
  methods: {
    async getFirstPageFromActuations() {
      await this.$store.dispatch(
        "sumarioList/updateOffset",
        0 * 8
      );
      await this.$store.dispatch("sumarioList/get");
      return await this.denunciations;
    },
    async getAPageFromActuations(numberPage) {
      if(typeof numberPage !== 'number') numberPage = 1;
      let url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/sumario`;
      let getOptions = {
        url,
        params: {
          limit: 8,
          offset : 8 * Number(numberPage - 1),
          getTotal : false
        }
      }
      const result = await Vue.prototype.$rest.get(getOptions)
      return await result.data.data;
    },
    getActuationsForTanstack() {
      const { data } = useQuery(
        ['actuations', 1],
	      this.getFirstPageFromActuations,
        {
          staleTime: 300000, // 5 minuto de frescura
          cacheTime: 300000, // 5 minutos en caché antes de ser eliminados
          refetchOnWindowFocus: false, // No actualizar al cambiar de pestaña.
        }
      );
      queryClient.setQueryData(['actuations', 1], data);
      return data;
    },
    async getActuationsForTanstackByPage(numberPage) {
       const response = await queryClient.fetchQuery(
        {
          queryKey: ['actuations', numberPage],
          queryFn: () => this.getAPageFromActuations(numberPage),
          staleTime: 300000, // 5 minutos de frescura
          cacheTime: 300000, // 5 minutos en caché
          refetchOnWindowFocus: false, // No actualizar al cambiar de pestaña.
        }
      );
      await queryClient.setQueryData(['actuations', numberPage], response);
      return response;
    },
    async getActuationsCachedByPage(numberPage) {
      let cachedData = await queryClient.getQueryData(['actuations', numberPage]);
      return await cachedData;
    },
    async saveActuationInCache(id, value) {
      // await queryClient.setQueryDefaults(['actuation ' + id], {
      //   staleTime: 300000, // 5 minutos de frescura
      //   cacheTime: 300000, // 5 minutos en caché
      // });
      // await queryClient.setQueryData(['actuation ' + id], {...value});
      await this.saveGenericDataInCache('actuation ' + id, value);
    },
    async getActuationFromCache(id) {
      // let actuationFromCache = await queryClient.getQueryData(['actuation ' + id]);
      let actuationFromCache = await this.getGenericDataFromCache('actuation ' + id);
      return actuationFromCache;
    },
    async deleteActuationFromCached(id) {
      await queryClient.removeQueries(['actuation ' + id]);
    },


    async saveGenericDataInCache(cacheName, value, time = 300000) {
      await queryClient.setQueryDefaults([cacheName], {
        staleTime: time, // 300000 = 5 minutos de frescura
        cacheTime: time, // 300000 = 5 minutos en caché
      });
      await queryClient.setQueryData([cacheName], {...value});
    },
    async getGenericDataFromCache(cacheName) {
      let dataFromCache = await queryClient.getQueryData([cacheName]);
      return dataFromCache;
    },
    async deleteGenericDataFromCached(cacheName) {
      await queryClient.removeQueries([cacheName]);
    },
  },
}