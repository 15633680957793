<template>

  <div :class="{'grid': !minimal}">
    <div v-if="!minimal" class="col-12 md:col-4 lg:col-3 lateral">
      <Listbox v-model="arrId"   :options="acusadosTodosSelect" dataKey="arrId" optionValue="arrId" optionLabel="name" id="listBoxAccuseds">
        <template #option="slotProps">
          <div class="flex justify-content-between">
            <span class="mt-2" :test-id="'acusadosContravener' + parseInt(slotProps.index + 1)">{{slotProps.option.name}}</span>
            <Button
              v-if="slotProps.option.type !== 'empty'"
              icon="pi pi-trash"
              class="p-button-danger p-button-lg px-2 py-2 m-1"
              style="z-index: 3"
              @click.stop="$_TemplateParentForm_delete(slotProps.option.arrId)"
            />
          </div>
        </template>
      </Listbox>
      <div class="button_Section_process volver"><!-- v-if="!arrId || filiacionDesconocida">-->
        <!-- <Button v-if="formType === 'denuncia'" label="Volver" icon="pi pi-back" @click="validacion('/sumario/denuncia/formulario')" test-id="buttonVolverContraventor"/>
        <Button v-if="formType === 'oficio'" label="Volver" icon="pi pi-back" @click="validacion('/sumario/denuncia/formulario/oficio')" test-id="buttonVolverContraventor"/>
        <Button v-if="formType === 'contravencional-oficio'" label="Volver" icon="pi pi-back" @click="validacion('/sumario/denuncia/formulario/contravencional/oficio')" test-id="buttonVolverContraventor"/>
        <Button v-if="formType === 'contravencional-denuncia'" label="Volver" icon="pi pi-back" @click="validacion('/sumario/denuncia/formulario/contravencional/denuncia')" test-id="buttonVolverContraventor"/> -->
        <Button  label="Volver" icon="pi pi-back" @click="validacion('/actuaciones/new/' + actuacionType + '/initial/')" test-id="buttonVolverContraventor"/>
      </div>
    </div>
    <div :class="{'col': !minimal}">
      <div :class="{ 'grid pad': true, 'hideForm' : formData.type === null || minimal }" >
        <div class="field-checkbox" test-id="checkFilacionDesconocida">
          <Checkbox id="binary" v-model="filiacionDesconocida" :binary="true" />
          <label for="binary">Filiación Desconocida</label>
        </div>
        <div class="field-checkbox" v-if="formType === 'contravencional-oficio' || formType === 'contravencional-denuncia'" test-id="checkDescripcionAcusado">
          <Checkbox id="binary" v-model="hasIdentikit" :binary="true" />
          <label for="binary">Descripción del contraventor/a</label>
        </div>
        <div class="field-checkbox" v-if="formType !== 'contravencional-oficio' && formType !== 'contravencional-denuncia'" test-id="checkDescripcionAcusado">
          <Checkbox id="binary" v-model="hasIdentikit" :binary="true" />
          <label for="binary">Descripción del {{formType === 'ufi-establecer-paradero' ? 'extraviado' : 'acusado/a'}}</label>
        </div>
        <div class="field-checkbox" v-if="formType !== 'contravencional-oficio' && formType !== 'contravencional-denuncia'" style="display: none">
          <Checkbox id="binary" v-model="hasIdentikit" :binary="true" />
          <label for="binary">Identikit</label>
        </div>
        <div class="field-checkbox" v-if="formType === 'oficio'" style="display: none">
          <Checkbox id="binary" v-model="hasIdentikit" :binary="true" />
          <label for="binary">Identikit específico</label>
        </div>
        <div class="field-checkbox" v-if="formType === 'oficio'" style="display: none">
          <Checkbox id="binary" v-model="hasIdentikit" :binary="true" />
          <label for="binary">Identikit generalizado</label>
        </div>
      </div>
      <div :class="{'card': !minimal, 'hideForm' : formData.type === null || filiacionDesconocida}" >
        <FormPersona
          :acusado="true"
          ref="acusado"
          :hidden="viewData.formPersona.condition.acusados === '' ? ['dataCondicion'] : []"
          @updateValue="$_TemplateParentForm_handleUpdateDocValue"
          :arrId="arrId"
          :getPersonByArrId="getAcusadoByArrId"
          typeOfPerson="acusados"
          :formType="formType"
          :Involucrados="acusadosTodosSelect"
        />
      </div>
      <div :class="{'card': !minimal, 'hideForm' : formData.type === null || hasIdentikit == false}" >
        <FormIdentikit
          ref="identikit"
          :formType="formType"
          :arrId="arrId"
          @updateValue="$_TemplateParentForm_handleUpdateIdentikitValue"
          @updateValueDescriptionOffender="$_TemplateParentForm_handleDescriptionOffender"
          @finishIdentikit="finishIdentikit()"
        />
      </div>
      <div class="button_Section_process guardar" v-if="arrId">
        <!-- <Button v-if="this.formType === 'contravencional-denuncia'" label="Guardar" icon="pi pi-back" @click="validacion('/sumario/denuncia/formulario/contravencional/denuncia')" test-id="buttonSaveContraventor"/>
        <Button v-if="this.formType === 'denuncia'" label="Guardar" icon="pi pi-back" @click="validacion('/sumario/denuncia/formulario')" test-id="buttonSaveAcusado"/>
        <Button v-if="this.formType === 'oficio'" label="Guardar" icon="pi pi-back" @click="validacion('/sumario/denuncia/formulario/oficio')" test-id="buttonSaveAcusado"/>
        <Button v-if="this.formType === 'contravencional-oficio'" label="Guardar" icon="pi pi-back" @click="validacion('/sumario/denuncia/formulario/contravencional/oficio')" test-id="buttonSaveContraventor"/> -->
        <Button  label="Guardar" icon="pi pi-back" @click='validacion("/actuaciones/new/" + actuacionType + "/initial")' :test-id="viewData.formAcusados.testIdButtonSave"/>
      </div>
    </div>
    <ModalTemplate 
      title="Información"
      type="informativo"
      :message="modalRefInformMessage"
      :showIcon="true"
      @confirm="closeModal()"
      severity="danger"
      ref="ModalRefInform"
      :closable="false"
    />
  </div>
</template>

<script>
import Listbox from 'primevue/listbox';
import FormPersona from '../../components/forms/FormPersona.vue'
import FormIdentikit from '../../components/forms/FormIdentikit.vue'
import ModalTemplate from '../../components/ModalTemplate.vue'

/*
Este es un modelo para componente que seran re utilizables muchas veces
*/
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'FormAcusados',
  components: {
    FormPersona, FormIdentikit, Listbox, ModalTemplate
  },
  props: {
    minimal: {
      type: String // vista minima del formulario si esta definido
    },
    predefined: {
      type: Object //string que se usara como base para llenar la declaracion de denuncia
    },
    aid: {
      type: String //string que se usara para volver a mostrar en Editor de denuncia al recargar
    },
    formType: String, //Defines the type of form
    viewData: Object,//Defines the type of form,
    actuacionType: String, 
  },
  data() {
    return {
      arrId: this.aid || null ,  
      modalRefInformMessage: ''
    }
  },
  mounted() {
    //go to top of the page
    window.scrollTo(0,0)
    if(typeof this.getAcusadoByArrId(this.$router.currentRoute.query.id) != 'undefined')  {
      this.arrId = this.$router.currentRoute.query.id
    }
    if(this.getAcusadoByArrId(this.arrId).sipeSearch === true){
      this.$refs.acusado.disabledFields = [
          "dataNombre",
          "dataApellido",
          "dataFechaNacimiento",
          "dataDomicilioSIPE",
        ];
      this.$refs.acusado.sipeSearch = true
    } else{
       this.$refs.acusado.disabledFields = [];
      this.$refs.acusado.sipeSearch = false
    }

  },
  created(){
    // console.log("dice: ", this.acusadosTodosSelect.length)
    if(this.$route.query.id == 'new')
    {
      this.arrId = this.acusadosTodosSelect[(this.acusadosTodosSelect.length)-1].arrId
    }

    // console.log('this.viewData', this.viewData)
  },
  computed: {
    ...mapGetters('sumario/acusados', [
      'acusadosTodos',
      'acusadosCount',
      'getAcusadoByArrId',
      'getEmptyDocAcusado',
      'getNewAcusadoArrId',
      'acusadosTodosSelect'
    ]),
    //manejo del dato de state
    formData: {
      get () {
        // console.log(this.predefined)
        // console.log(this.arrId)
        if (this.predefined && !this.arrId){
          // return this.getAcusadoByArrId(this.getNewAcusadoArrId)
          if (this.predefined.new) {
            return {
              doc: this.getEmptyDocAcusado(),
              identikit: {},
              descriptionOffender: [],
              type: 'empty',
              filiacionDesconocida: this.predefined.default.filiacionDesconocida,
              hasIdentikit: this.predefined.default.hasIdentikit
            }
          }
        }
        if (this.arrId) return this.getAcusadoByArrId(this.arrId)
        return {doc: this.getEmptyDocAcusado(),  type: null, filiacionDesconocida: false, hasIdentikit: false}
      },
    },
    //datos que se editan en este componente y no en componentes hijos especificos de Acusados
    filiacionDesconocida: {
      get () { return this.formData.filiacionDesconocida },
      set (value) { this.$_TemplateParentForm_updateValue('filiacionDesconocida', value ) }
    },
    hasIdentikit: {
      get () { return this.formData.hasIdentikit },
      set (value) { this.$_TemplateParentForm_updateValue('hasIdentikit', value ) }
    },
  },
  //comunicacion con hijos
  watch: {
    arrId: function (newValue) {
      // this.$refs.acusado.disabledFields = []
      // this.$refs.acusado.sipeSearch = false

      if(newValue != null && this.$router.currentRoute.query.id != newValue)
        this.$router.push({path: `/actuaciones/new/${this.actuacionType}/initial/acusado?id=${newValue}`})
      // if(newValue != null && this.$router.currentRoute.query.id != newValue && this.formType === 'oficio')
        // this.$router.push({path: `/sumario/denuncia/formulario/oficio/acusado?id=${newValue}`})
      // if(newValue != null && this.$router.currentRoute.query.id != newValue && this.formType === 'contravencional-oficio')
        // this.$router.push({path: `/sumario/denuncia/formulario/contravencional/oficio/contraventor?id=${newValue}`})
      // if(newValue != null && this.$router.currentRoute.query.id != newValue && this.formType === 'contravencional-denuncia')
        // this.$router.push({path: `/sumario/denuncia/formulario/contravencional/denuncia/contraventor?id=${newValue}`})
    },
    formData: function (newValue) {
      // console.log(newValue)
      if(typeof newValue != 'object') return
      this.$refs.acusado.setData( newValue.doc )

      //force the value of checkbox 'personaJuridica' to not be null
      if(newValue.doc.personaJuridica) {
        this.$refs.acusado.personaJuridica = newValue.doc.personaJuridica
      } else  {
        this.$refs.acusado.personaJuridica = false
      }
      if(newValue.identikit != undefined)
        this.$refs.identikit.setData( newValue.identikit )
      this.$_TemplateParentFor_setValue(newValue)
    }
  },

  methods: {
    //se ejecuta al ser activado por editor para mostrar luego de estar oculto
    //revisar si es necesario que lo use en la primera carga
    activeComponent(){
      this.$refs.acusado.setData( this.formData.doc )
    },

    $_TemplateParentFor_setValue(data){
      if (!this.predefined) return
      if (!this.predefined.valueFactory) return
      let text = this.predefined.valueFactory;

      //caracteristicas + identikit
      let charters = false
      if (text.indexOf('{textDescription}')>=0){
        charters = ''
        if  (data.identikit.description) charters = data.identikit.description
        for (let i in data.identikit){
          if (i == 'description') continue
          charters = charters + " " + i + " " + data.identikit[i].nombre.toLowerCase()
        }
      }
      if (charters){
        const regexCharters = /{textDescription}/ig;
        text = text.replace(regexCharters, charters)
      }

      //persona fisica

      if (data.doc.sexo){
        text = text.replace(/{sexo}/ig, data.doc.sexo.nombre)
      }

      if (data.doc.nombre){
        text = text.replace(/{nombre}/ig, data.doc.nombre)
      }

      if (data.doc.apellido){
        text = text.replace(/{apellido}/ig, data.doc.apellido)
      }
      if (data.doc.documento){
        text = text.replace(/{dni}/ig, data.doc.documento)
      }
      this.$emit("setValue", text)
    },

    // guarda cambios en stado
    async $_TemplateParentForm_updateValue( field, value ){

      if ( !this.formData.arrId ) {
        this.formData.arrId = await this.getNewAcusadoArrId
      }
      await  this.$store.dispatch('sumario/acusados/setAcusado', { ...this.formData,
        [field]: value
      })
      this.$store.dispatch("sumario/acusados/setEmptyTitle", this.viewData.formPersona.accused.emptyTitle);
      this.$_TemplateParentForm_setArrId()

    },

    // guarda cambios en identikit (especifico para identikit del componente acusado)
    async $_TemplateParentForm_handleUpdateIdentikitValue( field, value ){
      // console.log(this.formData)
      if ( !this.formData.arrId ) {
        this.formData.arrId = await this.getNewAcusadoArrId
      }
      if(field === "listAnswers"){
        await  this.$store.dispatch('sumario/acusados/setAcusado', { ...this.formData,
          identikit: [ ...value ]
        })
      }
      if(field === "numberQuestion"){
        await  this.$store.dispatch('sumario/acusados/setAcusado', { ...this.formData,
          identikitQuestion: value
        })
      }
      this.$_TemplateParentForm_setArrId()
    },

    async $_TemplateParentForm_handleDescriptionOffender( value ){
      if ( !this.formData.arrId ) {
        this.formData.arrId = await this.getNewAcusadoArrId
      }
      await  this.$store.dispatch('sumario/acusados/setAcusado', { ...this.formData,
        descriptionOffender: [ ...value ]
      })
      this.$_TemplateParentForm_setArrId()
    },

    // guarda cambios en doc (especifico para persona del componente acusado)
    async $_TemplateParentForm_handleUpdateDocValue( field, value ){
      // console.log(this.formData)
      if ( !this.formData.arrId ) {
        this.formData.arrId = await this.getNewAcusadoArrId
      }
      await  this.$store.dispatch('sumario/acusados/setAcusado', { ...this.formData,
        doc: { ...this.formData.doc, [field]: value }
      })
      this.$store.dispatch("sumario/acusados/setEmptyTitle", this.viewData.formPersona.accused.emptyTitle);
      this.$_TemplateParentForm_setArrId()

    },

    //le pone array id, es para el caso de nuevos desde editor de denuncia
    $_TemplateParentForm_setArrId(){
      if (!this.arrId) {
        this.arrId = this.formData.arrId
        if ( this.predefined ) {
          this.$emit("setArrId", this.arrId)
        }
      }
    },

    // elimina del array
    $_TemplateParentForm_delete(arrId) {
      this.arrId = null
      this.$store.dispatch('sumario/acusados/deleteAcusadoByArrId', arrId)
    },
    finishIdentikit(){
      this.arrId = null
    },
    closeModal(){
      this.$refs.ModalRefInform.close();
      this.$refs.acusado.$el.querySelector("#emailInput").select();
    },
    validateEmailError(){
      let validation = this.$rest.validateEmail(this.$refs.acusado?.email);
      if(this.$refs.acusado.condicion.nombre === 'Aprehendido' || this.$refs.acusado.condicion.nombre === 'Detenido'){
        this.modalRefInformMessage = 'Se requiere un email con formato válido.';
      } else {
        this.modalRefInformMessage = 'Se requiere un email válido o dejar el campo vacío si no tienes uno disponible.';
      }
      if(validation !== true){
        this.$refs.ModalRefInform.open();
      } 
      return validation;
    },
    validacion(route){
      if(!this.validateEmailError()) return;
      if(this.formType != 'ufi-flagrancia'){
        this.$store.dispatch("sumario/textResetEditsCounter","Contravener")
      }else{
        this.$store.dispatch("sumario/textResetEditsCounter","instructionDecree")
      }
      let errores = this.$refs.acusado.emitErrores()
      if(errores == true)
      {
        alert("Verifica los campos")
      }else
      {
        this.$router.push(route)
      }

    },
    
  }
}
</script>
<style scoped>
.card {
  background: #ffffff;
  padding: 2rem;
  -webkit-box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12);
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12);
  border-radius: 4px;
  margin-bottom: 2rem;
}
.hideForm {
  display: none;
}
.button_Section_process {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.button_Section_process:not(.volver){
  margin-right: 25px;
}

.guardar {
  margin-top: -20px;
}

.volver{
  margin-top: 10px;
}

.lateral > button {
  margin-top: 20px;
}
.pad {
  padding: 1rem .7rem;
}

.pad > div {
  margin-right: 30px;
}
</style>
