<template>
  <div class="toolbar">
    <div class="grid">
      <div class="toggleSidebar__btn">
        <Button
          icon="pi pi-bars"
          class="p-button-rounded p-button-text p-button-lg"
          @click="visibleLeft = !visibleLeft"
        />
      </div>
      <div class="general-container">
        <div class="top-menu">
          <div class="side-options">
            <!-- <h2 class="side-page-name" test-id="pageTitle">{{ currentTitle }}</h2> -->
             <img src="../../assets/logoSIIS.png" alt="Logo SIIS" style="height: 3em;">
             <p style="font-weight: 700; margin: 0;">Sistema Integral <br> Informático de Seguridad</p>
          </div>
          <div class="top-menu-alert" v-if="user.userdata.usuarioNombre">
            <!-- <FilesSearch type="topBar" /> -->
            <div>
              <div class="pipe one" />
              <!-- dividing sections -->
            </div>
            <Menu
              class="notif__Menu"
              id="overlayNotificationsOptions"
              ref="menuNotificationsOptions"
              :model="itemsNotificationMenu"
              :popup="true"
            />
            <div class="dependencyOptions-descriptions description">
              <h1 class="username description">
                {{ user?.userdata?.dependenciaNombre || user?.userdata?.juzgadoNombre }}
              </h1>
              <p class="userrole description">
                {{ user.userdata ? user.userdata.tipoUsuario : "SuperUser" }}
              </p>
            </div>
            <div>
              <div class="pipe two" />
              <!-- dividing sections -->
            </div>
            <div
              class="profileOptions"
              @click="toggleProfileOptionsMenu"
              aria-haspopup="true"
              aria-controls="overlayProfileOptions"
            >
              <div class="profilephoto" v-if="user.userdata.usuarioNombre">
                <img
                  class="profile-photo"
                  src="https://upload.wikimedia.org/wikipedia/commons/f/f4/User_Avatar_2.png"
                  :alt="user.userdata.apellido"
                />
              </div>
              <div
                class="profileOptions-descriptions description"
                v-if="user.userdata.usuarioNombre"
              >
                <p class="username description">
                  {{ user.userdata.nombre }} {{ user.userdata.apellido }}
                </p>
                <p class="userrole description top_username">{{ user.userdata.usuarioNombre }}</p>
              </div>
            </div>
            <Menu
              class="profile__Menu"
              id="overlayProfileOptions"
              ref="menuProfileOptions"
              :model="itemsProfileOptions"
              :popup="true"
            />
          </div>
          <div v-else>
            <Button
              icon="pi pi-sign-in"
              label="Iniciar Sesión"
              v-tooltip.bottom="'Iniciar sesión'"
              class="p-button-md p-button-primary px-2 py-3 m-1"
              @click="goToRoute('/login')"
            />
          </div>
        </div>
      </div>
    </div>
    <div>
      <Sidebar
        :isVisible.sync="visibleLeft"
        @logout="logout"
        @cleanStore="cleanStore"
        :userType="tipoUsuario"
      ></Sidebar>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Sidebar from "./Sidebar.vue";
import { getPageName } from "../../utils/getPageName";
// import FilesSearch from "@/components/FilesSearch"; Se comento por que la UH solicito eliminarlo (Aún debe ser validado por el PO)

export default {
  name: "DashboardTopMenu",
  components: {
    Sidebar,
    // FilesSearch,
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      tipoUsuario: (state) => state.user.userdata.tipoUsuario,
      formType: (state) => state.sumarioViews?.actuacion,
      actuacionType: (state) => {
        const type = state.sumario.formType;
        return type ? type.toUpperCase() : null;
      },
    }),
    currentTitle () {
      return getPageName(this.$router, this.actuacionType)
    }

  },
  mounted() {
    // console.log('this.formType', this.formType)

  },
  data() {
    return {
      visibleLeft: false,
      itemsNotificationMenu: [
        { label: "New order", icon: "pi pi-fw pi-shopping-cart" },
        { label: "Revisar nuevos", badge: "2", icon: "pi pi-fw pi-check-square" },
        { label: "Monthly reports", icon: "pi pi-fw pi-chart-line" },
        { label: "Comments", badge: "5", icon: "pi pi-fw pi-check-square" },
        { label: "Chargeback Request", badge: "3", icon: "pi pi-fw pi-info-circle" },
      ],
      itemsProfileOptions: [
        /* { label: "Mis datos", icon: "pi pi-fw pi-user" }, */
        {
          label: "Actualizar cache",
          icon: "pi pi-fw pi-replay",
          command: () => this.updateOffline(),
        },
        {
          label: "Cambiar contraseña",
          icon: "pi pi-fw pi-key",
          command: () => this.changeOfPassword(),
        },
        { label: "Cerrar Sesión", icon: "pi pi-fw pi-sign-out", command: () => this.logout() },
      ],
    };
  },
  methods: {
    toggleProfileOptionsMenu(event) {
      this.$refs.menuProfileOptions.toggle(event);
    },
    toggleNotificationsOptionsMenu(event) {
      this.$refs.menuNotificationsOptions.toggle(event);
    },
    // updateOffline(){
    //   // falta setJerarquia
    //   let actions = [
    //     "siis/setDependencias" ,
    //     "siis/setArticulos",
    //     "siis/setJuzgados",
    //     "siis/setCausaCaratula",
    //     "siis/setDelitos",
    //     "siis/setUserActuaciones",
    //     // "siis/setCategoriaTipoArchivoPDF",
    //     "siis/setSectorial",
    //     "siis/setModelo",
    //     "siis/setTipoDiligencia",
    //     "siis/setMarca",
    //     "siis/setDestino",
    //     "siis/setUFIs",
    //     "siis/setModusoperandi"
    //   ]
    //   actions.map(e=>{
    //     this.$store.dispatch(e, true)
    //   })
    // },
    async changeOfPassword() {
      this.goToRoute(`/editar-contrasena`);
    },
    goToRoute(route) {
      this.visibleLeft = false;
      this.$router.push(route);
      // location.reload()//cada que vamos a los sumarios, actualizamos para evitar cruzamiento de datos, entre un usuario y otro
      ////Cuando vamos a  una Actuacion seteamos el store
      this.$store.dispatch("sumario/resetState");
    },

    async logout() {
      this.$store.dispatch("sumarioList/resetActuaciones");
      await this.$store.dispatch("user/logout");
      this.$router.push("/"); //Cuando un usuario haga Logout lo redireccionaremos al Home Page
      location.reload();
    },
    async cleanStore() {
      this.$store.dispatch("sumario/resetState");
    },
    inputSeach(e) {
      if (e.type === "click" && this.showSearchTopBar === true) return;
      this.showSearchTopBar = !this.showSearchTopBar;
      setTimeout(() => {
        if (this.showSearchTopBar == true) {
          this.$refs.filesAutocomplete.$el.children[0].focus();
        }
      }, 100);
    },
    isDevMode() {
      return location.host === "dev.electro3.com.ar";
    },
  },
};
</script>

<style scoped>
div #overlayNotificationsOptions {
  min-width: 250px;
  margin-left: -10vw;
}
div #overlayProfileOptions {
  min-width: 250px;
  margin-left: -7vw;
}
.toolbar {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 27px 20px 2px 20px; */
  padding: 17px 20px 2px 20px;
  user-select: none;
  border-bottom: 0.1px solid rgba(65, 65, 65, 0.212);
  background: #F8F9FA;
  position: fixed;
  width: 100%;
  z-index: 1111;
}
.toggleSidebar__btn {
  margin-top: 1px;
  margin-bottom: 5px;
}
.grid {
  flex-wrap: nowrap;
}

>>> .p-sidebar-header {
  display: none;
}
>>> .p-sidebar-content {
  margin: 0;
  padding: 0;
  background-color: #252729;
  overflow: hidden;
}

div.active > .submenu {
  display: inherit;
  padding: 15px 15px;
}

div.submenu > .submenu {
  display: inherit;
}
.division {
  padding: 0;
  margin: 0;
}
.submenu {
  display: none;
  padding-left: 10px;
}
.submenu > div {
  cursor: pointer;
  padding: 10px 0;
  color: rgb(255, 255, 255);
}
.submenu > div.active {
  color: #dc3545;
}
.subMenuTitle {
  margin: 0;
  color: #686767;
  font-weight: 1000px;
}
.toolbar > div {
  align-items: center;
}
.tabmenu {
  padding-bottom: 12px;
}
.right {
  margin-left: auto;
}
.noWidth {
  width: 0;
}
.nameUser {
  color: rgb(255, 255, 255);
  float: right;
}
.top-menu {
  width: calc(100% - 80px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 5px;
  right: 12px;
}
.top-menu-alert {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
}

.profileOptions-descriptions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.dependencyOptions-descriptions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0;
}
.top-menu-alert-section {
  flex-wrap: nowrap;
  padding-top: 3px;
  padding-left: 17px;
}

.notif__Menu {
  margin-top: 2px;
  margin-right: 10px;
}

.profile__Menu {
  margin-top: 2px;
  margin-right: 10px;
}

.profile-photo {
  width: 35px;
  height: 35px;
  border-radius: 7px 0 0 7px;
}

.side-options {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}
.side-page-name {
  margin-top: 2px;
  margin-left: 15px;
  margin-bottom: 0;
}
.bell-button {
  position: relative;
}

>>> .pi-bell:before {
  font-size: 22px;
}

.p-button.p-button-secondary:enabled:focus,
.p-buttonset.p-button-secondary > .p-button:enabled:focus,
.p-splitbutton.p-button-secondary > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #30b2e198;
}
.pipe {
  margin: 0 5px 0 5px;
  border: #6d70723b 1px solid;
  padding: 0;
  height: 34px;
}
.direct_links {
  margin: 15px 0;
  background-color: #4b4b4b;
  border-radius: 5px;
  padding: 10px 5px;
}
.path_class {
  margin-left: 10px;
  list-style: none;
  font-weight: 700;
  word-spacing: 0.4rem;
}
.direct_links:hover {
  background-color: #7a7979;
}
.new_form {
  margin: 0;
  padding: 5px;
  list-style: none;
  background-color: inherit;
  border-radius: 7px;
}
.path_nuevo > span:hover {
  animation: underline 4s linear alternate infinite;
}
.new_form:hover {
  background-color: #2e2d2d;
}
@keyframes underline {
  0% {
    text-decoration: #b9b8b8 2px underline;
    color: #a4a5a7;
  }
  25% {
    text-decoration: #707070 2px underline;
    color: #d3d3d3;
  }
  50% {
    text-decoration: #a0a0a0 2px underline;
    color: #bdbdbd;
  }
  75% {
    text-decoration: #cfcfcf 2px underline;
    color: #d8d8d8;
  }
  100% {
    text-decoration: #f1f1f1 2px underline;
    color: #fcfcfc;
  }
}
.path_nuevo {
  list-style: none;
  background-color: inherit;
  margin: 10px 0;
  font-weight: 700;
  color: rgb(196, 196, 196);
}
.alerts-number {
  position: relative;
  top: -20px;
  right: 15px;
  font-size: 11px;
  color: #fff;
  background-color: #2196f3;
  border-radius: 7px;
  padding: 2px 5px;
}
.profileOptions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profile-photo:focus {
  border: #2196f3 solid 2px;
}
.profilephoto {
  margin: 4px 1px 0 1px;
  padding: 0 2px;
}
.profileOptions:hover {
  border-radius: 4px;
  height: fit-content;
  cursor: pointer;
  background-color: hsl(0, 0%, 91%);
}
.description {
  font-size: 12px;
  font-weight: bold;
  color: rgb(21, 21, 25);
  margin: 1px 3px;
}
/* @media screen and (max-width: 1200px) { */
@media screen and (max-width: 651px) {
  .description {
    display: none;
  }
  .side-page-name {
    display: none;
  }
  .profile-photo {
    border-radius: 7px 7px;
  }
  .pipe.one {
    display: none;
  }
}
.username {
  color: #323232;
  font-size: 11px;
}
.userrole {
  color: #686868;
  font-size: 10px;
}
>>> .p-menu .p-menuitem-link {
  margin: 0 15px;
  border-radius: 4px;
}
.searchTopBar {
  cursor: pointer;
}
.invisibleDiv {
  position: absolute;
  z-index: 2;
  height: 30px;
  width: 30px;
  margin-left: 187px;
  cursor: pointer;
}
</style>@/router/RoutesConfig/actuacionesConfig/actuacionesConfig@/router/RoutesConfig/depositoConfig
