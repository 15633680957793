<template>
  <div class="toolbar">
    <br />
    <div class="feature-intro title">
      <h1>Gestor de Archivos</h1>
    </div>
    <div>
      <TemplateTable
        tableTitle="Gestor de Archivos"
        reference="parametros/gestor-archivos/edit"
        :loading="loading"
        v-on:selectItem="$emit('selectItem', $event)"
        :idTable="idTable"
        :url="url"
        :data="data"
        :modal="modal"
        apiversion="2"
        :keyShow="keyShow"
        :enableFilterMultiple="true"
        :showLink="showLink"
        :filtersFields="filtersFields"
        :disableButtonEdit="false"
        :multiSelect="true"
        :enableSelectAllCheckbox="true"
        :enableEditButtons="false"
        :enableGlobalSearch="false"
        :enableAddButton="{ enabled: true, name: 'gestor-archivos-new' ,  params: { id: 'add' }}"
      >
        <template #LinksBody="slotProps">
          <div class="flex flex-column md:flex-row">
          <a class="file__link" :href="`${url}/${slotProps.data['ArchivopdfID']}?download=true`" target="_blank">
              <Button 
                icon="pi pi-eye"
                class="p-button-success p-button-rounded p-button-outlined mr-2" 
                :style="{color: '#2196f3'}"
                :disabled="!slotProps.data['ExistingFile']" 
                v-tooltip.left="!slotProps.data['ExistingFile'] ? 'Archivo no encontrado' : 'Visualizar archivo'"
              />
            </a>
            <Button 
              icon="pi pi-pencil" 
              class="p-button-warning p-button-rounded p-button-outlined mr-2"
              :style="{color: '#ffa800'}"
              v-tooltip.left="'Editar archivo'"
              @click="goToEditMode(slotProps.data)"
            />
            <Button 
              icon="pi pi-trash" 
              class="p-button-danger mr-2 p-button-rounded p-button-outlined roundeds-buttons"
              :style="{color: '#d32f2f'}"
              v-tooltip.left="'Eliminar archivo'"
              @click="confirmDelete(slotProps.data)"
            />
            
          </div>
        </template>
      </TemplateTable>
    </div>
    <ModalTemplate 
      title="Confirmación" 
      type="confirmativo" 
      message="¿Está seguro que desea eliminar este archivo?" 
      :showIcon="true" 
      :dismissableMask="true"
      severity="danger" 
      @confirm="onConfirm"
      @cancel="onCancel"
      ref="ModalRefConfirmDelete" 
    />
  </div>
</template>

<script>
import axios from 'axios';
import ModalTemplate from "../../../components/ModalTemplate.vue";

export default {
  name: "Gestordearchivos",
  components: {ModalTemplate },
  props: {
    modal: {
      type: Boolean,
    },
  },
  data() {
    return {
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/files/archivopdf",
      customName: [],
      data: null,
      customColumn: [],
      idTable: "ArchivopdfID",
      loading: true,
      filtersFields: [{ name: "Nombre", field: "Nombre" }],
      keyShow: ["Nombre", "Tipo", "Detalle", "Links"],
      showLink: true,
      fileToDelete: null,
    };
  },
  methods: {
    goToEditMode(doc) {
      this.$router.push(`/parametros/gestor-archivos/edit/${doc.ArchivopdfID}`);
    },
    confirmDelete(doc) {
      this.fileToDelete = doc; 
      this.$refs.ModalRefConfirmDelete.open(); 
    },
    async onConfirm() {
      try {
        if (this.fileToDelete) {
          await axios.delete(`${this.url}/${this.fileToDelete.ArchivopdfID}`, {
            withCredentials: true,
          });
          this.$refs.ModalRefConfirmDelete.close();
          this.$router.go(0); 
        }
      } catch (error) {
        console.error("Error al eliminar el archivo:", error);
        this.$refs.ModalRefConfirmDelete.close();
      } finally {
        this.fileToDelete = null;
      }
    },
    onCancel() {
      this.fileToDelete = null;
      this.$refs.ModalRefConfirmDelete.close();
    },
  },
};
</script>

<style scoped>
.title {
  margin-left: 30px;
}
.file__link {
  text-decoration: none;
}
</style>
