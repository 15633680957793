<template>
  <div class="p-fluid formgrid grid">
    <div class="field col-12 md:col-12 sm:col-12 condition" test-id="buttonTipoDenunciante">
      <Dropdown
        dataKey="nombre"
        v-model="dataTipoEfecto"
        :options="this[viewData[formType].objetos.type]"
        optionLabel="nombre"
        placeholder="Elija condición"
        class="lg:col-5 md:col-7 sm:col-12"
        test-id="InputTipoEfecto"
        :filter="true"
      />
    </div>

    <EfectoForm
      :actuationMode="true"
      :showSolicitud="false"
      :showSaveButton="false"
      :style="{ display: effectTypeNew ? 'inline' : 'none' }"
      ref="EfectoForm"
    />
    <template v-if="!effectTypeNew">
      <div class="field col-12 md:col-3">
        <label for="tipo">Tipo</label>
        <InputText
          class="input"
          type="text"
          placeholder="tipo"
          v-model="dataTipo"
          test-id="inputTipoEfectos"
        />
        <label :class="{ visible: errores.tipo, disable: dataTipo == null }">
          {{ errores.tipo }}</label
        >
      </div>
      <div class="field col-12 md:col-3">
        <label for="marca">Marca</label>
        <InputText
          class="input"
          type="text"
          placeholder="marca"
          v-model="dataMarca"
          test-id="inputMarcaEfectos"
        />
        <label :class="{ visible: errores.marca, disable: dataMarca == null }">
          {{ errores.marca }}</label
        >
      </div>
      <div class="field col-12 md:col-3">
        <label for="modelo">Modelo</label>
        <InputText
          class="input"
          type="text"
          placeholder="modelo"
          v-model="dataModelo"
          test-id="inputModeloEfectos"
        />
        <label :class="{ visible: errores.modelo, disable: dataModelo == null }">
          {{ errores.modelo }}</label
        >
      </div>
      <div class="field col-12 md:col-3">
        <label for="color">Color</label>
        <InputText
          class="input"
          type="text"
          placeholder="color"
          v-model="dataColor"
          test-id="inputColorEfectos"
        />
        <label :class="{ visible: errores.color, disable: dataColor == null }">
          {{ errores.color }}</label
        >
      </div>
      <div class="field col-12 md:col-3">
        <label for="numId">ID / Serie / Patente</label>
        <InputText
          class="input"
          type="text"
          placeholder="numId"
          v-model="dataNumId"
          test-id="inputIdEfectos"
        />
        <label :class="{ visible: errores.numId, disable: dataNumId == null }">
          {{ errores.numId }}</label
        >
      </div>

      <div class="field col-12 md:col-3">
        <label for="details">Detalles</label>
        <InputText
          class="input"
          type="text"
          placeholder="details"
          v-model="dataDetails"
          test-id="inputDetalleEfectos"
        />
        <label :class="{ visible: errores.details, disable: dataDetails == null }">
          {{ errores.details }}</label
        >
      </div>

      <div class="field-checkbox col-12 md:col-3" test-id="checkEfectos">
        <Checkbox id="tiene-seguro" v-model="dataTieneSeguro" :binary="true" />
        <label for="tiene-seguro">Tiene seguro</label>
      </div>
    </template>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import EfectoForm from "../../pages/deposito/Efecto-form.vue";
/*
Este es un modelo para componente que seran re utilizables muchas veces
*/
export default {
  name: "FormObjetos",
  components: {
    EfectoForm,
  },
  props: {
    minimal: {
      type: String,
    },
    formType: String, //Defines the type of form
    listaDeEfectos: { type: Array, default: () => [] },
    arrId: String,
    viewData: Object,
  },
  mounted() {
    this.$nextTick(() => {
      const footerButtonsFromTForm = document.getElementById("footer-buttons");
      if (footerButtonsFromTForm) footerButtonsFromTForm.remove();
    });
    setTimeout(() => {
      this.searchAndShowEffect(this.$router.currentRoute.query.id);
    }, 100);
  },
  data() {
    return {
      dataCondicion: "",
      // optionsEfectos:[
      //     { "id": "Denunciado", "nombre": "Denunciado" },
      //     { "id": "Recuperado", "nombre": "Recuperado" },
      //     { "id": "Secuestrado", "nombre": "Secuestrado" } ],
      tipo: "",
      marca: "",
      modelo: "",
      color: "",
      numId: "",
      details: "",
      tieneSeguro: false,
      tipoEfecto: "",
      fields: ["tipo", "marca", "modelo", "color", "numId", "details", "tieneSeguro", "tipoEfecto"],
      errores: {
        tipo: null,
        marca: null,
        modelo: null,
        color: null,
        numId: null,
        details: null,
        tieneSeguro: null,
        tipoEfecto: null,
      },
      TFormValues: "",
      // TFormValuesOldValue: '{}'
    };
  },
  created() {},
  computed: {
    ...mapState({
      objetosAllTypes: (state) => state.siis.objetosAllTypes,
      objetosOnlySecuestrado: (state) => state.siis.objetosOnlySecuestrado,
      sumario: (state) => state.sumario,
    }),
    ...mapGetters("sumario/robados", [
      "robadosTodos",
      "robadosCount",
      "getRobadoByArrId",
      "getNewRobadoArrId",
      "robadosTodosSelect",
    ]),
    // ...mapState({
    //   sumario: (state) => state.sumario,
    // }),
    dataTipo: {
      get() {
        return this.tipo;
      },
      set(value) {
        this.$_TemplateForm_update("tipo", value);
      },
    },
    dataMarca: {
      get() {
        return this.marca;
      },
      set(value) {
        this.$_TemplateForm_update("marca", value);
      },
    },
    dataModelo: {
      get() {
        return this.modelo;
      },
      set(value) {
        this.$_TemplateForm_update("modelo", value);
      },
    },
    dataColor: {
      get() {
        return this.color;
      },
      set(value) {
        this.$_TemplateForm_update("color", value);
      },
    },
    dataNumId: {
      get() {
        return this.numId;
      },
      set(value) {
        this.$_TemplateForm_update("numId", value);
      },
    },
    dataDetails: {
      get() {
        return this.details;
      },
      set(value) {
        this.$_TemplateForm_update("details", value);
      },
    },
    dataTieneSeguro: {
      get() {
        return this.tieneSeguro;
      },
      set(value) {
        this.$_TemplateForm_update("tieneSeguro", value);
      },
    },
    dataTipoEfecto: {
      get() {
        let classEfecto = this.listaDeEfectos.find((e) => e.arrId == this.arrId);

        if (!this.tipoEfecto) {
          //si el efecto seleccionado ya tenia definido un Tipo de efecto, entonces usamos el que solia tener
          if (classEfecto?.tipoEfecto?.nombre != undefined) {
            this.tipoEfecto = classEfecto?.tipoEfecto;
          } else {
            //caso contrario establecemos uno por defecto
            this.tipoEfecto =
              this.formType.includes("denuncia") || this.formType.includes("preliminar-otros")
              ? { id: "Denunciado", nombre: "Denunciado" }
              : { id: "Secuestrado", nombre: "Secuestrado" };
            if(this.arrId != null )this.$_TemplateForm_update('tipoEfecto', this.tipoEfecto);
          }
        }
        return this.tipoEfecto;
      },
      set(value) {
        this.$_TemplateForm_update("tipoEfecto", value);
        this.TFormValues.tipoEfecto = value;
      },
    },
    TFormValuesData: {
      get() {
        this.TFormValues = this.$refs.EfectoForm.$refs.EfectoFormTForm.values;
        return this.TFormValues;
      },
      // set (value) {
      //   this.TFormValues = value;
      //   return value;
      // }
    },
    effectTypeNew: {
      get() {
        let effectSelected = this.robadosTodos.find((element) => element.arrId === this.arrId);
        if (effectSelected?.doc !== undefined && effectSelected?.doc?.newFormatEffect === undefined)
          return true;
        return effectSelected?.doc?.newFormatEffect;
      },
      set(value) {
        let effectSelected = this.robadosTodos.find((element) => element.arrId === this.arrId);
        if (effectSelected?.doc !== undefined && effectSelected?.doc?.newFormatEffect === undefined)
          return true;
        return effectSelected?.doc?.newFormatEffect;
      },
    },
  },
  watch: {
    dataTipo(v) {
      if (v != null) {
        this.errores.tipo =
          v.length > 100 ? "Este campo no puede contener más de 100 Caracteres" : null;
      }
    },
    dataMarca(v) {
      if (v != null) {
        this.errores.marca =
          v.length > 100 ? "Este campo no puede contener más de 100 Caracteres" : null;
      }
    },
    dataModelo(v) {
      if (v != null) {
        this.errores.modelo =
          v.length > 100 ? "Este campo no puede contener más de 100 Caracteres" : null;
      }
    },
    dataColor(v) {
      if (v != null) {
        this.errores.color =
          v.length > 100 ? "Este campo no puede contener más de 100 Caracteres" : null;
      }
    },
    dataNumId(v) {
      if (v != null) {
        this.errores.numId =
          v.length > 100 ? "Este campo no puede contener más de 100 Caracteres" : null;
      }
    },
    dataDetails(v) {
      if (v != null) {
        this.errores.details =
          v.length > 500 ? "Este campo no puede contener más de 500 Caracteres" : null;
      }
    },
    dataTieneSeguro(v) {
      if (v != null) {
        this.errores.tieneSeguro =
          v.length > 100 ? "Este campo no puede contener más de 100 Caracteres" : null;
      }
    },
    // dataTipoEfecto(v)
    // {
    //   if(v != null)
    //   {
    //     this.errores.tipoEfecto = v.length > 100 ? 'Tiene que seleccionar un tipo de efecto' : null
    //   }  []
    // },
    TFormValues: {
      handler(newValue) {
        this.updateStoreOfEffects(newValue);
      },
      deep: true,
    },
    arrId(v) {
      setTimeout(() => {
        this.searchAndShowEffect(v);
      }, 100);
    },
  },
  methods: {
    setData(data) {
      // console.log("CHILD data", data)
      // console.log("data:",data)
      for (let i of this.fields) {
        this[i] = data[i] || null;
      }
    },
    $_TemplateForm_update(field, value) {
      let actualPath = this.$router.history.current.path.split("/");
      if (actualPath[actualPath.length - 2] !== "denuncia")
        this.$store.dispatch("sumario/textResetEditsCounter", "instructionDecree");
      this.$store.dispatch("sumario/textResetEditsCounter", "Contravener");
      this.$emit("updateValue", field, value);
    },
    emitErrores() {
      for (let i in this.errores) {
        if (this.errores[i] != null) {
          return true;
        }
      }
      return false;
    },
    compareObjectsToArray(obj1, obj2) {
      let differences = [];
      for (let key in obj1) {
        if (!obj2.hasOwnProperty(key) || obj1[key] !== obj2[key]) {
          let difference = {};
          difference[key] = obj1[key];
          differences.push(difference);
        }
      }
      for (let key in obj2) {
        if (!obj1.hasOwnProperty(key)) {
          let difference = {};
          difference[key] = obj2[key];
          differences.push(difference);
        }
      }
      return differences;
    },
    updateStoreOfEffects(effectData) {
      for (let key in effectData) {
        this.$_TemplateForm_update(key, effectData[key]);
      }
    },
    // searchAndShowEffect(arrId) {
    //   if(Object.keys(this.getRobadoByArrId(arrId)?.doc || {}).length !== 0) {
    //     this.TFormValues = this.getRobadoByArrId(arrId).doc;
    //     this.$refs.EfectoForm.$refs.EfectoFormTForm.values = this.getRobadoByArrId(arrId).doc;
    //     this.$refs.EfectoForm.$refs.EfectoFormTForm.params.CategoriaID.dropdown.changeDropdown(this.getRobadoByArrId(arrId).doc.CategoriaID);
    //     this.$refs.EfectoForm.$refs.EfectoFormTForm.params.SubcategoriaID.dropdown.changeDropdown(this.getRobadoByArrId(arrId).doc.SubcategoriaID);
    //   }
    // },
    searchAndShowEffect(arrId) {
      const robado = this.getRobadoByArrId(arrId);
      if (robado && robado.doc && Object.keys(robado.doc).length !== 0) {
        this.TFormValues = robado.doc;

        if (this.$refs.EfectoForm && this.$refs.EfectoForm.$refs.EfectoFormTForm) {
          this.$refs.EfectoForm.$refs.EfectoFormTForm.values = robado.doc;
          this.$refs.EfectoForm.$refs.EfectoFormTForm.params.CategoriaID.dropdown.changeDropdown(
            robado.doc.CategoriaID
          );
          this.$refs.EfectoForm.$refs.EfectoFormTForm.params.SubcategoriaID.dropdown.changeDropdown(
            robado.doc.SubcategoriaID
          );
        }
      }
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.disable {
  display: none;
}
.visible {
  color: #dc3545;
  text-align: center;
  margin-top: 3px;
}
</style>
