var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content borderShadow",class:{ 'disabled': _vm.disabled },attrs:{"tabindex":"0"}},[_c('Card',{class:{
      borderShadow: true,
      passed:
        _vm.alertas() == 'ok' &&
        _vm.warningAlerts() == 'ok' &&
        _vm.viewData.showPeoplesAlertas &&
        _vm.showValidation == true,
      error: _vm.alertas() != 'ok' && _vm.viewData.showPeoplesAlertas && _vm.showValidation == true,
      warningPeoples: _vm.warningAlerts() != 'ok' && _vm.showValidation == true,
    },attrs:{"tabindex":"0","test-id":"cardPeople"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"title flex justify-content-between flex-wrap"},[_c('div',{attrs:{"test-id":"cardPeopleTitle"}},[_vm._v(" "+_vm._s(_vm.viewData.cardDenunciante.title)+" ")]),_c('Button',{staticClass:"p-button-rounded p-button-info p-button-outlined",attrs:{"icon":"pi pi-plus","disabled":_vm.disabled,"test-id":"cardPeoplePlusIcon"},on:{"click":function($event){return _vm.ManageActions('create')}}})],1)]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"search1"},[_vm._l((_vm.denunciantes),function(item,i){return _c('div',{key:i,staticClass:"flex justify-content-between flex-wrap",on:{"mouseover":function($event){return _vm.changeOrAddShowButton(item.doc.arrId)},"mouseleave":_vm.onMouseleave}},[_c('div',{staticClass:"title-icon"},[_c('div',{staticClass:"edit_action"},[_c('Button',{staticClass:"p-button-rounded p-button-info p-button-text minus10",attrs:{"icon":"pi pi-pencil","test-id":"cardPeoplePencil"},on:{"click":function($event){return _vm.ManageActions('edit',item)}}})],1),_c('div',{staticClass:"info_section",attrs:{"test-id":"ordenPublico"}},[_c('div',{staticClass:"flex"},[_c('p',{staticClass:"title"},[_c('span',{attrs:{"test-id":'elemento-denunciante' + i}},[_vm._v(_vm._s(_vm.nombreYapellidos(item)))])]),(_vm.colorTag(item.doc?.condicion?.nombre) !== '')?_c('div',{staticClass:"ml-4 mt-2"},[_c('Tag',{staticClass:"mt-10",class:_vm.colorTag(item.doc?.condicion?.nombre),attrs:{"severity":_vm.colorTag(item.doc?.condicion?.nombre),"value":item.doc?.condicion?.nombre,"rounded":"","test-id":"tagProtagonista"}})],1):_vm._e()]),(!item.ordenPublico)?_c('p',{staticClass:"address"},[_vm._v(" "+_vm._s(item.doc.domicilioSIPE)+" ")]):_vm._e()])]),_c('div',{staticClass:"delete_action"},[(
                _vm.viewData.formDenunciaDeleteButton.show &&
                _vm.showDeleteButton === item.doc.arrId
              )?_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:('Eliminar'),expression:"'Eliminar'",modifiers:{"bottom":true}}],staticClass:"p-button-rounded p-button-danger p-button-outlined",staticStyle:{"margin-right":"10px"},attrs:{"icon":"pi pi-trash"},on:{"click":(event) => _vm.ManageActions('delete', item, event)}}):_vm._e(),(
                _vm.showDeleteButton === item.doc.arrId && !_vm.sumario.actuationEditModeActive
              )?_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:('Pegar en el Relato'),expression:"'Pegar en el Relato'",modifiers:{"bottom":true}}],staticClass:"p-button-rounded p-button-info p-button-outlined pasteInto",attrs:{"icon":"pi pi-plus"},on:{"click":function($event){return _vm.pasteInDeposition(item)}}},[_c('img',{staticClass:"paste-button",attrs:{"alt":"share icon","src":require("../assets/share_icon.svg")}})]):_vm._e()],1)])}),(
            _vm.alertas() != 'ok' && _vm.viewData.showPeoplesAlertas && _vm.showValidation == true
          )?_c('div',{staticClass:"text-right",staticStyle:{"color":"#dc3545"}},[_vm._v(" "+_vm._s(_vm.alertas())+" ")]):_vm._e(),(
            _vm.warningAlerts() != 'ok' &&
            _vm.viewData.showPeoplesAlertas &&
            _vm.showValidation == true
          )?_c('div',{staticClass:"text-right",staticStyle:{"color":"#ffc107"}},[_vm._v(" "+_vm._s(_vm.warningAlerts())+" ")]):_vm._e()],2)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }