<template>
    <div class="tableContainer">
        <DataTable
          :value="data"
          :loading="loading"
          responsiveLayout="scroll"
          :paginator="true"
          :rows="10"
          :rowsPerPageOptions="[10, 20, 50]"
        >
          <Column field="para" header="Para">
            <template #body="slotProps">
              <span>{{ slotProps.data.para }}</span>
            </template>
          </Column>
    
          <Column field="asunto" header="Asunto">
            <template #body="slotProps">
              <span >
                {{ slotProps.data.asunto }}
              </span>
            </template>
          </Column>
    

          <Column field="date" :header="dateColumn">
            <template #body="slotProps">
              {{ dateColumn === 'Enviado' ? slotProps.data.enviado : slotProps.data.creationDate }}
            </template>
          </Column>

          <Column header="Acciones">
            <template #body="slotProps">
              <Button
                type="button"
                v-tooltip.left="'Editar correo'"
                icon="pi pi-pencil"
                class="p-button-warning mr-2"
            ></Button>
            <Button
            type="button"
            v-tooltip.left="'Eliminar correo'"
            icon="pi pi-trash"
            class="p-button-danger mr-2"
        ></Button>
            </template>
          </Column>

          <template #footer>
                 Mostrando correos del {{ startIndex }} al {{ endIndex }} de un total de {{ totalItems }}

          </template>
        </DataTable>
      </div>
</template>
  
<script>
    export default {
    components: { },
    name: 'ShowEmails',
    props: {
    data: {
    type: Array,
    default: null
    },
    dateColumn:{
      type:String,
      default: "Enviado"
    },
    

    },
    data() {
    return {
        loading: false, 
        totalItems: 1000, 
        currentPage: 1, // Página actual (comienza en 1)
        pageSize: 5
    };
    },
    computed: {
    startIndex() {
      return (this.currentPage - 1) * this.pageSize + 1;
    },
    endIndex() {
      return Math.min(this.currentPage * this.pageSize, this.totalItems);
    },
  },
    created(){
      this.totalItems=this.data.length 

    },
    methods: {

    }
    }
</script>


<style scoped>
.tableContainer{
    padding: 50px;
    background-color: white;
}
</style>
