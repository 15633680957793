<template>
  <div class="page2">

    <div class="feature-intro title flex justify-content-left">
      <h1 class="pl-0 pt-2">Efectos Cargados</h1>
    </div>
    <div>
      <TemplateTable
        tableTitle="Deposito Judicial"
        reference="deposito/efectos"
        :loading="loading"
        v-on:selectItem="$emit('selectItem', $event)"
        :idTable="idTable"
        :url="url"
        :data="data"
        :modal="modal"
        :titles="titles"
        order="exp_pre_EfectoDeposito.FechaCreacion desc"
        apiversion="2"
        :getLimit="10"
        :formatDoc="formatDoc"
        :styles="styles"
        :resizableColumns="true"
        columnResizeMode="expand"
        :keyShow="keyShow"
        :filtersFields="filtersFields"
        :multiSelect="true"
        :enableSelectAllCheckbox="false"
        :enableDeleteButton="enableDeleteButton"
        :enableViewButtons="true"
        :enableGlobalSearch="false"
        :enableAddButton="{ enabled: true, name: 'newEfecto' ,  params: { id: 'add' }}"
        :enableUploadFile="false"
        :enableFilterMultiple="true"
        :enableRefresh="true"
        :dropdown="dropdown"
        v-on:datosFiltros="onResultados"
        emptyMsg="No existen registros para mostrar"
        :classesDivContainer="classesDivContainer"
        :removeLimit="true"
        ref="TemplateTable"
      />

        <!-- :enableEditButtons="true" -->
      
    </div>

    <!-- <div class="ml-5">
        <Button label="Seleccionar" @click="submit"/>
      </div> -->
    <!-- POR AHORA,  enableGlobalSearch REQUIERE QUE enableEditButtons SEA TRUE!!-->
    <!-- <button>submit</button> -->
  </div>
</template>

<script>
import dateMixing from "@/mixins/dateMixin"
import { mapState } from "vuex";

export default {
  name: "depositoTable",
  mixins: [
    dateMixing
  ],
  props: {
    modal: {
      type: Boolean,
    },
  },
  mounted() {
    this.$watch(
      () => this.$refs.TemplateTable._data?.selectedCards,
      (newValue) => {
        this.enableDeleteButton = this.$rest.esTipoUsuario(['Super User','User Tester','Super Usuario de E3'], this.user);
        let hasActuations = newValue.some(effect => effect.Origen === "Actuacion");
        if(hasActuations){
          this.enableDeleteButton = false;
        } 
      }
    );
  },
  async created() {
    this.filtersFields.push({
      name: "Actuaciones",
      field: "exp_pre_VinculoActuacionDeposito.TipoActuacion",
      type: "multiple",
      multiple: true,
    })
    this.dropdown["exp_pre_Usuario.UsuarioID"].loadDropdown()
    this.dropdown["exp_pre_EstadoEfecto.TipoEstadoEfectoID"].loadDropdown()
    this.dropdown.MarcaID.loadDropdown()
    this.dropdown.ModeloID.loadDropdown()
    this.dropdown.CategoriaID.loadDropdown()
    this.dropdown.SubcategoriaID.loadDropdown()
    if(this.$rest.esTipoUsuario(['Super User', 'User Tester', 'Super Usuario de E3'], this.user)){       
      // this.filtersFields.push({ name: "Dependencia", field:"DependenciaID", })
      this.filtersFields.push({
        name: "Dependencias",
        field: "DependenciaID",
        type: "multiple",
        multiple: true,
      })    
      this.dropdown["DependenciaID"].loadDropdown()      
      this.keyShow.splice(this.keyShow.length - 1, 0, "dependencia");//adding 'ubicacion' in penultimate position
      this.titles.dependencia = "Ubicación";
    } 
    this.filtersFields.sort((a, b) => a.name.localeCompare(b.name));
    this.dropdown["exp_pre_VinculoActuacionDeposito.TipoActuacion"].options = this.vinculoActuacionDepositoOptions;
  },
  data() {
    return {
      user: this.$store.state.user,
      filteredLocation: [],
      url: process.env.VUE_APP_BACKEND_ENVIRONMENT + "/deposito",
      customName: [],
      data: null,
      customColumn: [],
      idTable: "EfectoDepositoID",
      loading: true,
      
      filtersFields: [
        // { name: "Nombre", field:"nombre" },
        { name: "N° Motor", field: "NumeroMotor" },
        { name: "N° Chasis", field: "Chasis" },
        { name: "Imei", field: "Imei" },
        { name: "Dominio", field: "Dominio" },
        { name: "Año", field: "Anio" },
        { name: "Categoría", field: "CategoriaID" },
        { name: "Subcategoría", field: "SubcategoriaID" },
        { name: "Fecha de Creacion", field: "exp_pre_EfectoDeposito.FechaCreacion", type: 'date'},
        { name: "Marcas",field: "MarcaID"},
        { name: "Modelos", field: "ModeloID"},
        { name: "Visible", field: "Visible", type: 'dropdown'},
        { name: "Estado", field: "exp_pre_EstadoEfecto.TipoEstadoEfectoID"},
        { name: "Depositario Judicial", field: "exp_pre_Usuario.UsuarioID"},
        // { name: "Tipo de actuación", field: "exp_pre_VinculoActuacionDeposito.TipoActuacion", hideOperators: true},
        // { name: "Infracción", field: "exp_pre_VinculoInfraccionDeposito.VinculoInfraccionDepositoID", type: 'total', hideOperators:true, hideValue:true},
      ],
      dropdown: {
        "exp_pre_Usuario.UsuarioID": {
          optionValue: "UsuarioID",
          optionLabel: "apellido_nombre",
          options: [],
          refreshDropdown: () => {this.$store.dispatch("siis/setUserList", 'efectos', true)
          },
          loadDropdown: async () => {
            await this.$store.dispatch("siis/setUserList",'efectos', true);
            this.dropdown["exp_pre_Usuario.UsuarioID"].options = this.$store.state.siis.userList;
          },
        }, 
        "exp_pre_EstadoEfecto.TipoEstadoEfectoID": {
          optionValue: "TipoEstadoEfectoID",
          optionLabel: "Nombre",
          options: [],
          refreshDropdown: () => {this.$store.dispatch("siis/setEstadosEfectoDeposito", true)
          },
          loadDropdown: async () => {
            await this.$store.dispatch("siis/setEstadosEfectoDeposito");
            // console.log(this.$store.state);
            this.dropdown["exp_pre_EstadoEfecto.TipoEstadoEfectoID"].options = this.$store.state.siis.estadosEfectoDeposito;
          },
        },   
        ModeloID: {
          optionValue: "ModeloID",
          optionLabel: "Nombre",
          options: [],
          refreshDropdown: () => {this.$store.dispatch("siis/setModelo", true)},
          loadDropdown: async () => {
            await this.$store.dispatch("siis/setModelo");
            this.dropdown["ModeloID"].options = this.$store.state.siis.modelo;
          },
        },             
      MarcaID: {
          optionValue: "MarcaID",
          optionLabel: "nombre",
          options: [],
          refreshDropdown: () => {this.$store.dispatch("siis/setMarca", true)},
          loadDropdown: async () => {
            await this.$store.dispatch("siis/setMarca");
            this.dropdown["MarcaID"].options = this.$store.state.siis.marca;
          },
          changeDropdown: async (v) => {
            await this.$store.dispatch("siis/setModelo", true);
            this.dropdown.ModeloID.options = this.$store.state.siis.modelo.filter(
              (item) => item.MarcaID === v.MarcaID
            );
          },
        },
        CategoriaID: {
          optionValue: "CategoriaID",
          optionLabel: "Nombre",
          options: [],
          refreshDropdown: () => {this.$store.dispatch("siis/setCategoriasDeposito", true)
           
          },
          loadDropdown: async () => {
            await this.$store.dispatch("siis/setCategoriasDeposito");
            this.dropdown.CategoriaID.options = this.$store.state.siis.categoriasDeposito;
          },
          // changeDropdown: async (v) => {
          //   console.log("valor de la v",v)
          //   await this.$store.dispatch("siis/setCategoriasDeposito", true);
          //   this.dropdown.ModeloID.options = this.$store.state.siis.modelo.filter(
          //     (item) => item.MarcaID === v.MarcaID
          //   );
          //   console.log("valor droppp nuevo>>>>>",this.dropdown.ModeloID.options)
          // },
        },
        SubcategoriaID: {
          optionValue: "SubcategoriaID",
          optionLabel: "Nombre",
          options: [],
          refreshDropdown: () => {this.$store.dispatch("siis/setSubCategoriasDeposito", true)
           
          },
          loadDropdown: async () => {
            await this.$store.dispatch("siis/setSubCategoriasDeposito");
            this.dropdown.SubcategoriaID.options = this.$store.state.siis.subcategoriasDeposito;
          },
         
          // changeDropdown: async (v) => {
          //   console.log("valor de la v",v)
          //   await this.$store.dispatch("siis/setCategoriasDeposito", true);
          //   this.dropdown.ModeloID.options = this.$store.state.siis.modelo.filter(
          //     (item) => item.MarcaID === v.MarcaID
          //   );
          //   console.log("valor droppp nuevo>>>>>",this.dropdown.ModeloID.options)
          // },
        },
        DependenciaID:{
          optionValue: "id",
          optionLabel: "dependencia",
          options: [],
          refreshDropdown: () => {
            this.$store.dispatch("siis/setDependencias", true)
          },
          loadDropdown: async () => {
            await this.$store.dispatch("siis/setDependencias");
            // console.log("formato: ", this.$store.state.siis.dependencia)
            this.dropdown["DependenciaID"].options = this.$store.state.siis.dependencia;
          }
        },
        Visible:{
          optionValue: "value",
          optionLabel: "name",
          options: [
            { value: 1, name: "Si" },
            { value: 0, name: "No" }
            ],
        },
        "exp_pre_VinculoActuacionDeposito.TipoActuacion": {
          optionValue: "value",
          optionLabel: "name",
          options: []
        },
      },

      keyShow: [
        "FechaCreacion",
        "categorias",
        "subcategoria",
        "marcas",
        "modelos",
        "tipo",
        // "dependencia",
        "TipoActuacion",
        "Estado",
        // "Año",
        // "Chasis",
        // "NumeroMotor",
        // "Dominio",
        // "n_serie",
        // "Imei",
        // "abonado",
        // "color",
        // "descripcion",
        // "n_sumario_actuaciones",
        // "juzgado",
        // "nombre_damnificado",
        // "causa_caratula",
        // "nombre_imputado",
        // "secretario_actuacion",
        // "domicilio_hecho",
      ],
      titles: {
        FechaCreacion: "Creación",
        categorias: "Categoría",
        subcategoria: "Sub-Categoría",
        marcas: "Marca",
        modelos: "Modelo",
        tipo:"Tipo",
        TipoActuacion: "Tipo Actuación",        
        // dependencia: "Ubicación",
        Estado:"Estado",
        // Año: "Año",
        // Chasis: "N° Chasis",
        // NumeroMotor: "N° Motor",
        // Dominio: "Dominio",
        // n_serie: "N° Serie",
        // Imei: "Imei",
        // abonado: "Abonado",
        // color: "Color",
        // descripcion: "Descripcion",
        // n_sumario_actuaciones: "N° Sumario/ Actuaciones Preliminares",
        // juzgado: "Juzgado",
        // nombre_damnificado: "Damnificado",
        // causa_caratula: "Causa/Caratula",
        // nombre_imputado: "Imputado",
        // secretario_actuacion: "Secretario de Actuacion",
        // domicilio_hecho: "Domicilio del hecho y/o allanamiento",
      },
      formatDoc: (col, data) => {
        switch (col) {
          case "FechaCreacion": 
            return this.date2Es(data[col], true)
            
          case "CategoriaID":
            return this.$store.state.siis.categoriasArticulosObj[data[col]]
              .name;
          case "SubcategoriaID":
            return this.$store.state.siis.categoriasArticulosObj[data[col]]
              .path;
          case "TipoActuacion":
          case "NumeroInfraccion":
            if(data["TipoActuacion"] === null && data.NumeroInfraccion === null && data["TiposumarioID"] === null){
              return "Objeto no vinculado"
            } else if(data["TiposumarioID"] !== null){
              return data["TiposumarioID"]
            }
            return data["NumeroInfraccion"] ? "Infraccion" : data["TipoActuacion"];
          default:
            return data[col];
        }
      },
      styles: {
        default: {
          header: { width: "320px" },
          body: { width: "200px" },
        },
        jerarquia: {
          header: { width: "200px" },
          body: { width: "200px" },
        },
      },
      classesDivContainer: {
        Estado: function(value) {
          switch (value) {
            case "En Dependencia":
              return "eSuccess uppercase";
            case "Depósito Cavic":
            case "Depósito 9 de Julio":
              return "eWarning uppercase";
            case "A Justicia":
              return "eAlert uppercase"
            case "Orden de destrucción":
              return "eDanger uppercase";
            case "En Peritaje":
              return "eInfo uppercase";
            case "Entrega al propietario":
              return "ePrimary uppercase";
            case "Precarga":
            return "eNew uppercase";
          }
        },
      },
      enableDeleteButton: null,
    };
  },
  computed:{
    ...mapState({
      viewData: (state) => state.sumarioViews,
    }),
    vinculoActuacionDepositoOptions: {
      get() {
        let options = [];
        let filterName = '';
        for (const actuationName in this.viewData) {
          filterName = Object.keys(this.viewData[actuationName]?.filters || {})[0];
          options.push({name: filterName, value: this.viewData[actuationName]?.filters?.[filterName]?.deposito?.value});
        }
        options = options.filter(obj => !Object.values(obj).some(value => value === undefined));
        options.push({ 
          name: "Infracción", 
          value: "NULL", 
          injectString: '"exp_pre_VinculoInfraccionDeposito.VinculoInfraccionDepositoID":[{"operator":"IS NOT NULL","value":""}]', 
          singleFilter: true 
        });
        return options;
      },
    },
  },
  methods: {
    onResultados(mostrar) {
      let datos = mostrar;
      console.log("datos desde indice", datos);
    },
    downloadXls() {
      window.location.assign(`${this.url}/d1_indice?getXLS`);
      //  const r= await axios.get(`${this.url}/d1_indice?getXLS` , { withCredentials: true })
      //  console.log(r)
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title {
  margin-left: 20px;
}
</style>
