<template>
  <div class="toolbar">
    <br>
    <div class="feature-intro title">
        <h1>{{ config.title }}</h1>
    </div>
    <div>
        <TemplateTable
          :apiversion="config.apiversion"
          :disableButtonEdit="config.disableButtonEdit"
          :enableAddButton="config.enableAddButton"
          :enableEditButtons="config.enableEditButtons"
          :enableGlobalSearch="config.enableGlobalSearch"
          :enableSelectAllCheckbox="false"
          :enableFilterMultiple="true"
          :filtersFields="config.filtersFields"
          :idTable="config.idTable"
          :keyShow="config.keyShow"
          :loading="config.loading"
          :getLimit="config.apiversion === 'v2' ? 10 : null"
          :modal="config.modal"
          :multiSelect="config.multiSelect"
          :ordenable="config.ordenable"
          :reference="config.reference"
          :secondFilter="config.dropdownData"
          :tableTitle="config.title"
          :titles="config.titles"
          :url="config.url"
          :dropdown="dropdown"
          v-on:selectItem="$emit('selectItem', $event)"
        >
        <template
           v-if="config.idTable==='TipodiligenciaID'"
           #ReviewBody="slotProps">
          <div style="text-align: center;">
            <Button class="p-button-success" icon="pi pi-search" lab @click="review(slotProps)"/>
          </div>
        </template>
      </TemplateTable>
    </div>
  </div>

</template>
<script>
export default {
name: 'TemplateView',
props: {
  config: {
    type: Object,
    default: () => ({})
  }
},
data(){
  return {
    dropdown: {},
  }
},
methods: {
  initDropdown(){
    const dropdownName = this.config.addDropdownFilter.name;
    this.dropdown = {
      [dropdownName]: {
        optionValue: this.config.addDropdownFilter.optionValue,
        optionLabel: this.config.addDropdownFilter.optionLabel,
        options: this.config.addDropdownFilter.options,
      }
    };

  },
  addDropdownFilter(newFilter){
    const filterIndex = this.config.filtersFields.findIndex(filter => filter.field === newFilter.new.field);
    if (filterIndex === -1) {
      // Si el filtro no existe, agrégalo
      this.config.filtersFields.push(newFilter.new);
      this.loadDropdownData(newFilter.dispatch, newFilter.new.field);
    }

  },
  loadDropdownData(dispatchAction, field) {
    const stateName = this.config.addDropdownFilter.stateName;
    this.$store.dispatch(dispatchAction).then(
      () => {
        this.dropdown[field].options = this.$store.state.siis[stateName];
      });
  },
  //diligencias
  review(data){
    console.log(data.data.TipodiligenciaID)
    this.$router.push("tipo-diligencia/preview/"+data.data.TipodiligenciaID)
  },

},
mounted(){
  if (!this.config || !this.config.filtersFields) {
    console.warn('config.filtersFields is not defined');
    return;
  }
  // console.log('this.config.filtersFields::: ', this.config.filtersFields);
  if (!this.config.addDropdownFilter) return;

  this.initDropdown()
  this.addDropdownFilter(this.config.addDropdownFilter)

},



};
</script>

<style scoped>
  .title  {
    margin-left: 30px;
  }
</style>
