<template>
  <div :class="viewer ? '' : 'page'">
    <div v-if="!viewer">        
      <div class="feature-intro">
        <h1>Editando {{ localConfig.pageName }}</h1>
        <p>Modifique los datos que desea EDITAR</p>
      </div>
    </div>

    <div v-if="!showTForm || loading" class="spinner-wrapper">
      <ProgressSpinner
        style="width: 50px; height: 50px"
        strokeWidth="5"
        fill="#EEEEEE"
        animationDuration="1.5s"
      />
      <p v-if="loading" class="loading-text">GUARDANDO...</p>
    </div>

    <TForm
      v-show="showTForm && !loading"
      :data="localConfig.data"
      :nombres="localConfig.names"
      :errors="localConfig.errors"
      :mensajes="localConfig.mensajes"
      :dropdown="localConfig.dropdown || {}"
      :designEdit="localConfig.design"
      :url="localConfig.url"
      :labels="localConfig.labels || {}"
      :placeholder="localConfig.placeholder || {}"
      mode="edit"
      :viewer="viewer"
      :viewerID="viewerID"
      @errorDuplicatedEntry="handleDuplicatedEntryError"
      @saveAttempt="onSaveAttempt"
      @finishPetition="onfinishPetition"
    />

    <ModalTemplate
      title="Error de Duplicación"
      type="informativo"
      :message="errorMessage"
      :showIcon="true"
      @confirm="$refs.ModalRefDuplicated.close()"
      severity="danger"
      ref="ModalRefDuplicated"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import ModalTemplate from "@/components/ModalTemplate.vue";

export default {
  props: {
    viewer: { type: Boolean, default: false },
    viewerID: { type: String, default: "" },
    config: { type: Object, default: () => ({}) },
  },
  components: {
    ModalTemplate,
  },
  data() {
    return {
      showTForm: false,
      loading: false,
      errorMessage: "",
    };
  },
  async created() {
    await this.$store.dispatch("siis/setMarca");
    await this.$store.dispatch("siis/setSubCategoriasDeposito");
    await this.$store.dispatch("siis/setCategoriasDeposito");
    await this.$store.dispatch("siis/setTipoDeposito");
    await this.$store.dispatch("siis/setDepartamentales");
  },
  async mounted() {
    if (this.localConfig.dropdown) {
      if (this.localConfig.dropdown.id_categoria) {
        this.localConfig.dropdown.id_categoria.changeDropdown = this.handleCategoriaChange;
      }
      if (this.localConfig.dropdown.id_subcategoria) {
        this.localConfig.dropdown.id_subcategoria.changeDropdown = this.handleSubcategoriaChange;
      }
      if (this.localConfig.dropdown.id_marca) {
        this.localConfig.dropdown.id_marca.refreshDropdown = this.refreshMarcaDropdown;
      }
      if (this.localConfig.dropdown.DepartamentalID) {
        this.localConfig.dropdown.DepartamentalID.refreshDropdown = await this.$store.dispatch("siis/setDepartamentales", true);
        this.localConfig.dropdown.DepartamentalID.options = this.$store.state.siis.departamentales;
      }
    }
    this.showTForm = true;
  },

  computed: {
    ...mapState({
      marca: (state) => state.siis.marca,
      subcategorias: (state) => state.siis.subcategoriasDeposito,
      categorias: (state) => state.siis.categoriasDeposito,
      tipo: (state) => state.siis.tipoDeposito,
      departamentales: (state) => state.siis.departamentales,
    }),
    localConfig() {
      if (this.config) {
        return this.config.data;
      } else {
        return {
          data: {},
          names: {},
          labels: {},
          placeholder: {},
          errors: {},
          mensajes: {},
          design: [],
          url: "",
          dropdown: {},
        };
      }
    },
  },
  methods: {
    onSaveAttempt() {
      this.loading = true;
      this.showTForm = false;
    },
    handleDuplicatedEntryError(message) {
      this.showTForm = true;
      this.loading = false;
      this.errorMessage = message;
      this.$refs.ModalRefDuplicated.open();
    },
    onfinishPetition(){
      this.showTForm = true;
      this.loading = false;
    },
    handleCategoriaChange(value) {   
      this.localConfig.dropdown.id_categoria.options = this.subcategorias.filter(
        (item) => item.id_categoria === value
      );
    },
    handleSubcategoriaChange(value) {
      if (this.localConfig.dropdown && this.localConfig.dropdown.id_subcategoria) {
        this.localConfig.dropdown.id_subcategoria.options = this.subcategorias.filter(item => item.id_categoria === value);
      }
    },
    refreshMarcaDropdown() {
      this.$store.dispatch("siis/setMarca", true);
    },
  },
};
</script>

<style scoped>
.spinner-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.loading-text {
  margin-top: 10px;
}
</style>
