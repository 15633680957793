/*
TOAST OBJECT

Attribute 	Type 	Default 	Description
message 	String 	-- 	Message text/html (required)
type 	String 	success 	One of success, info, warning, error, default
position 	String 	bottom-right 	One of top, bottom, top-right, bottom-right,top-left, bottom-left
duration 	Number 	3000 	Visibility duration in milliseconds, set to 0 to keep toast visible
dismissible 	Boolean 	true 	Allow user dismiss by clicking
onClick 	Function 	-- 	Do something when user clicks
onDismiss 	Function 	-- 	Do something after toast gets dismissed
queue 	Boolean 	false 	Wait for existing to dismiss before showing new
pauseOnHover 	Boolean 	true 	Pause the timer when mouse on over a toast
*/
var VueCli
var axios
var store
var toastQueue = []

const toastPermission = (msg) => {
    // console.log("toastPermission",toastQueue, msg)
    if (toastQueue.indexOf(msg) >= 0) return false
    toastQueue.push(msg)

    setTimeout(() => {
        // console.log("clear toast", msg)
        toastQueue.splice(toastQueue.indexOf(msg), 1)
    }, 9000)
    return true

}

const siis = {
    test: async() => {
        // console.log("siis stiore>>>>", this)
        VueCli.$toast.open({
            message: "HI",
            position: 'top-right',
            type: 'error',
            duration: 3000,
            onClick: () => {
                alert("aa")
            }
        });
        try {

            let response = await axios.get('https://localhost:3000', { withCredentials: true }, {
                // headers: { ...options.headers }
            })
            // console.log("response", response)
        } catch (e) {
            console.log("error", e)
        }
    },
    get: (options) => new Promise(async(resolve, reject) => {
        try {
            if (options.url.search('http://') < 0 && options.url.search('https://') < 0) options.url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}${options.url}`
            let params = options.params || {}
            let response = await axios.get(options.url, { withCredentials: true, params }, {
                headers: {...options.headers }
            })
            let result = await siis.processResponse(response, options)
            return resolve(result);

        } catch (e) {
            return siis.processResponse(e.response, options)

        }
    }),
    delete: (options) => new Promise(async(resolve, reject) => {
        try {
            if (options.url.search('http://') < 0 && options.url.search('https://') < 0) options.url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}${options.url}`

            let response = await axios.delete(options.url, { withCredentials: true }, {
                headers: {...options.headers }
            })
            let result = await siis.processResponse(response, options)
            return resolve(result);

        } catch (e) {
            // console.log("sale x aqui", e.response, e)
            return siis.processResponse(e.response, options)

        }
    }),
    post: (options) => new Promise(async(resolve, reject) => {
        try {
            if (options.url.search('http://') < 0 && options.url.search('https://') < 0) options.url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}${options.url}`
            let params = options.params || {}
            let response = await axios.post(options.url, options.formData, { withCredentials: true, params }, {
                headers: {...options.headers }
            })
            let result = await siis.processResponse(response, options)
            return resolve(result);

        } catch (e) {
            // console.log("sale x aqui", e.response, e)
            return reject(siis.processResponse(e.response, options));

        }
    }),
    put: (options) => new Promise(async(resolve, reject) => {
        try {
            if (options.url.search('http://') < 0 && options.url.search('https://') < 0) options.url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}${options.url}`
            let params = options.params || {}
            let response = await axios.put(options.url, options.formData, { withCredentials: true, params }, {
                headers: {...options.headers }
            })

            let result = await siis.processResponse(response, options)
            return resolve(result);

        } catch (e) {
            // console.log("sale x aqui", e.response, e)
            return reject(siis.processResponse(e.response, options));

        }
    }),
    toast_open(options){
        if (toastPermission(options.message)) VueCli.$toast.open(options);
    },
    processResponse: (response, options) => new Promise(async(resolve, reject) => {
        if (!VueCli.prototype.$offline.onlineMode){
          VueCli.$toast.open({ message: "OFFLINE SIIS: Acción no disponible.", position: 'top-right', type: 'error', duration: 3000 });
          return reject({})
        }
        let successMsg = options.success || "La carga se realizo con exito."

        let errAlert = () => {
            let msg = typeof response.data == 'object' ? JSON.stringify(response.data) : response.data;
            alert(msg);
        }
        // console.log("response: ", response)
        if (!response) console.error("response: ", response, options )
        if (!response || (response && response.status == '401')) {
            try {
                // console.log("err 401>", response.data)
                if (response.data.error == 'Unauthorized') {
                    let msg = "La sesión ha caducado o no tiene permiso para la acción que desea realizar. Abriremos una nueva pestaña para que vuelva a loguearse. Luego de loguearse vuelva a intentarlo."
                    const currentRoute = this.$router?.currentRoute?.value?.path || window.location.pathname;
                    if (toastPermission(msg)) {
                        setTimeout(() => {           
                            if (currentRoute != '/') {
                                VueCli.$toast.open({ message: msg, position: 'top-right', type: 'error', duration: 5000, onClick: errAlert });
                                window.open('/login?closeTab', '_blank');
                            }
                        }, 3000)
                    }
                    return reject(response);
                }
                
            } catch (e) {
                console.error("Error: ", e, response)
            }
            if (response) VueCli.$toast.open({ message: "Ups! Algo fallo, te pedimos disculpas y te ayudaremos a resolverlo. Por favor, haz click en este cartel rojo, te aparecera un nuevo mensaje, envia una captura de pantalla con esa información a la mesa de ayuda tecnica.", position: 'top-right', type: 'error', duration: 0, onClick: errAlert });


        } else if (response && (response.status != '200') || (response.data && response.data.error != '')) {
            VueCli.$toast.open({ message: "Ocurrio un error al cargar.", position: 'top-right', type: 'error', duration: 3000, onClick: errAlert });
            return reject(response)
        }
        // console.log("toastPermission(successMsg)", toastQueue, toastPermission(successMsg), successMsg)
        //se comenta esto para que no se muestre el mensaje de que todo salio bien ↓
        if (toastPermission(successMsg) && response.data.type === 'create') VueCli.$toast.open({ message: successMsg, position: 'top-right', type: 'success', duration: 3000 });
        return resolve(response)
    }),
    /**
     * @param {String} tipoPermiso 
     * @param {user} user 
     * @returns boolean
     * @description Verifica si el usuario tiene el permiso para realizar la acción o ver información. Actualmente no se usa. Pero existe algo similar que controla los accesos por la propiedad de tipoUsuario del user.userdata
     */
    tienePermisos(tipoPermiso, user) {
        // console.log(user)
        if (tipoPermiso === "PermisoAdministradorDelSistema") return user.userdata.permisos.find(e => e.PermisousuarioID === "PermisoSuperUsuario")
        return user.userdata.permisos.find(e => e.PermisousuarioID === tipoPermiso)
    },

    /**
     * @param {String []} tipoUsuario
     * @param {user} user 
     * @returns boolean
     * @description Verifica si el usuario es el usuarioTipo ingresado por parámetros autorizado para realizar la acción o ver información
     */
    esTipoUsuario(tipoUsuario, user) {
        // console.log(user.userdata.tipoUsuario)
        // console.log(tipoUsuario)
        return tipoUsuario.includes(user.userdata.tipoUsuario);
    },
    haveDependencia(user) {
        if(user.dependencia !== undefined){
            return true;
        } else{
            return false;
        }
    },
     /**
     * @param {String []} email
     * @returns boolean | string
     * @description 
     */
    validateEmail(email) {
        if(email === '' || email === undefined || email === null) return true;
        if(!email.includes('.')) return false;
        if(!email.includes('@')) return false;
        return true;
    },
    validateRequestEmail(emailData) {
        const requiredProps = ['sender', 'addressee', 'subject', 'emailBody'];
        const hasAllRequiredProps = requiredProps.every(prop => emailData.hasOwnProperty(prop));
        const hasEmptyValues = Object.values(emailData).some(v => !v && v !== false);
        if (!hasAllRequiredProps || hasEmptyValues) {
            VueCli.$toast.open({ 
                message: `No se puede enviar el email porque faltan datos para enviarlo.`, position: 'top-right', type: 'error', 
                duration: 0 
            });
            throw new Error("No se puede enviar el email porque faltan datos para enviarlo.");
        }
    },
    async sendEmail(emailData) {
        try {
            await siis.validateRequestEmail(emailData);
            for (const index in emailData.addressee) {
                let formData = {
                  Remitente: emailData.sender, Destinatario: emailData.addressee[index], Asunto: emailData.subject,
                  Mensaje: emailData.emailBody,
                };
                let optionsPost = {
                  url: `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/correo-electronico`,
                  formData: formData, headers: { "Content-Type": "multipart/form-data" },
                };
                let response = await siis.post(optionsPost);
                VueCli.$toast.open({ message: "El e-mail fue enviado con éxito", position: "top-right", type: "success", duration: 5000,});
            }
        } catch (error) {
            console.error('Error al enviar el email:', error);
            await siis.showEmailErrorMessage();
        }
    },
    showEmailErrorMessage() {
        VueCli.$toast.open({
            message: `
                No se puede enviar el email. Comuníquese con la 
                <a 
                    href="https://api.whatsapp.com/send/?phone=542644446909&text=Hola%20estoy%20utilizando%20el%20SIIS,%20no%20puedo%20enviar%20correo%20electr%C3%B3nico%20de%20" 
                    target="_blank"
                    style="text-decoration: none; color: inherit;"
                >
                    <u>Mesa de Ayuda.</u>
                </a>
            `,
            position: 'top-right',
            type: 'error',
            duration: 0,
        });
    },
}



module.exports = (Store, Vue, Axios) => {
    VueCli = Vue;
    axios = Axios;
    store = Store;
    return {
        post: siis.post,
        get: siis.get,
        delete: siis.delete,
        put: siis.put,
        test: siis.test,
        toast_open: siis.toast_open,
        esTipoUsuario: siis.esTipoUsuario,     
        haveDependencia: siis.haveDependencia,   
        validateEmail: siis.validateEmail,   
        sendEmail: siis.sendEmail,   
    }

}