/**
 * Retorna el color para una etiqueta (Tag) basado en el tipo de afectación patrimonial.
 * @param {Object} value - El objeto de afectación.
 * @returns {string} El color como string para la etiqueta (Tag).
 */
export function colorTagPatrimonialAffectation(value) {
  const tipoAfectacion = value?.tipo;
  
  const colorMap = {
    permuta: "info",
    credito: "warning",
    debito: "warning",
    cripto: "success",
    transferencia: "danger",
  };

  // Retorna el color correspondiente o 'default' si no se encuentra
  return colorMap[tipoAfectacion] || "default";
}

/**
 * Retorna un string formateado con el CBU o bien patrimonial basado en el tipo de transacción.
 * @param {Object} transaction - El objeto de la transacción.
 * @returns {string} El string formateado para mostrar.
 */
export function returnCbuOrEstate(transaction) {
  const { tipo, identificadorOrigen, identificadorDestino, estate, NroTarjeta, bienEntregado } = transaction;

  const tipoMap = {
    "Consumo T.C.": `${identificadorOrigen}${identificadorDestino ? ' - ' + identificadorDestino : ''}`,
    debito: `${NroTarjeta}`,
    credito: `${NroTarjeta}`,
    cripto: `${identificadorOrigen}${identificadorDestino ? ' - ' + identificadorDestino : ''}`,
    transferencia: `${identificadorOrigen}${identificadorDestino ? ' - ' + identificadorDestino : ''}`,
    permuta: `${bienEntregado.nombre}`,
    efectivo:'EFECTIVO'
  };

  // Retorna el valor correspondiente o un string vacío si no se encuentra
  return tipoMap[tipo] || '';
}

/**
 * Retorna un tipo de transacción formateado para mostrar.
 * @param {string} tipo - El tipo de transacción.
 * @returns {string} El tipo de transacción formateado.
 */
export function getFormattedTransactionType(tipo) {
  const tipoFormattedMap = {
    transferencia: "Transferencia",
    cripto: "Criptomoneda",
    credito: "Consumo T.C.",
    debito: "Consumo T.D.",
    efectivo: "Permuta",
    permuta: "Permuta"
  };

  // Retorna el tipo formateado o un string genérico si no se encuentra
  return tipoFormattedMap[tipo] || "Tipo Desconocido";
}
