<template>
  <div class="toolbar flex-column">
    <SuccessAndFail :msjEnable.sync="msjEnable" :mensajeDialog="mensaje" :buttomAceptar="buttomAceptar"
      :buttomCancelar="buttomCancelar" :icon.sync="iconModal" @Aceptar="ClickAceptar" :aceptar="aceptarLabel"
      :cancelar="cancelarLabel" />
    <div>
      <TTableFilters v-if="$offline.onlineMode" ref="Filters" apiversion="v2" :dropdown="filterDropdown"
        :filtersFields="filtersFields" :enableFilterMultiple="enableFilterMultiple" @search="getData(true)" />
    </div>

    <DataTable
      :value="denunciationsToShow"
      :paginator="false"
      class="p-datatable-forms col-12"
      dataKey="id"
      :filters="filters"
      :loading="loading || disabledRowBotton"
      test-id="Denuncias"
    >
      <template #empty> No hay denuncias para mostrar. </template>
      <template #loading> Descargando, espere por favor. </template>

      <Column v-for="column in keyShow" :field="column.title" :key="column.title" :sortable="false"
        :sortField="column.fieldName" v-if="showColumnOrNot(column.title)">
        <template #header>
          <div class="flex align-items-center">
            {{ column.title }}
            <Button v-if="order[column.fieldName]" :icon="order[column.fieldName].icon || 'pi pi-sort-alt'"
              class="p-button-rounded p-button-secondary p-button-text" @click="setOrderBy(column.fieldName)" />
          </div>
        </template>
        <template #body="slotProps">
          <div :test-id="'fila-nombre' + slotProps.index" :id="
              !isOfflineDoc(slotProps.data)
                ? slotProps.data.ActuacionUsuarioDependencia.find(
                    (e) => e.UsuarioID === 'Sin Usuario Asignado' && e.Fechahasta === null
                  )
                  ? 'sin-propietario'
                  : ''
                : ''
            " class="flex align-items-center">
            <div class="flex mr-2">
              <Button v-if="booleans(slotProps, 'triangleButton', column.title)" v-tooltip.left="
                  slotProps.data.Numero
                    ? 'No fue asignado ningún propietario'
                    : 'Falta numero de sumario'
                " icon="pi pi-exclamation-triangle" class="p-button-rounded p-button-danger"
                :test-id="'danger-' + slotProps.data.ActuacionID" />
            </div>
            <!-- {{column.title}}
            {{slotProps.data}} -->
            <div :class="getClass(column, slotProps.data)">
              {{ formatDoc(column.title, slotProps.data) }}
            </div>
          </div>
        </template>
      </Column>
      <Column class="flex-row">
        <template #body="slotProps">
          <div class="flex" style="text-align: right">
            <div class="flex mr-2" v-if="isOfflineDoc(slotProps.data) && $offline.onlineMode">
              <!-- {{slotProps.data.ActuacionID}} -->
              <Button v-tooltip.left="'Subir'" icon="pi pi-upload"
                class="p-button-rounded p-button-success p-button-outlined" @click="subirActuacion(slotProps)" />
            </div>
            <div v-if="booleans(slotProps, 'numeroExtracto')">
              <Button v-tooltip.left="'Recientemente asignada'" label="Extracto de Denuncia" icon="pi pi-check"
                class="p-button-danger p-button-sm" :test-id="'Asignar-numero' + slotProps.data.ActuacionID"
                @click="OpenModalAddNsum(slotProps)" :style="{
                  height: '40px ',
                  width: '200px',
                  marginRight: '0.5rem ',
                }" />
            </div>
            <div class="flex justify-content-center align-items-center" style="width: 100%"
              v-else-if="visibleButtons()">
              <!-- <div class="flex mr-2">              
                <router-link :to="`/actuaciones/view/${slotProps.data.ActuacionID}`" class="decoration__links">    
                 
                  <Button
                    v-tooltip.left="'Visualizar esta Actuacion'"
                    icon="pi pi-eye"
                    class="p-button-rounded p-button-outlined"
                    :test-id="'visualizar-act' + slotProps.data.ActuacionID"
                  />
                </router-link>
              </div> -->
              <!-- <div class="flex">
                <Button
                  v-if="authorizedUser() && !isOfflineDoc(slotProps.data)"
                  v-tooltip.left="'Eliminar esta Actuacion'"
                  icon="pi pi-trash"
                  class="
                    p-button-rounded p-button-outlined p-button-danger
                    mr-2
                  "
                  id="boton-eliminar"
                  @click="deleteActuacion(slotProps)"
                />
              </div> -->
              <div class="action-buttons">
                <a :href="`/actuaciones/view/${slotProps.data.ActuacionID}`" rel="noopener noreferrer">
                  <Button
                  icon="pi pi-eye"
                  :disabled="disabledRowBotton"
                  class="p-button-text mb-2 action-button"
                  aria-label="Ver Actuación"
                />
                </a>
                <SplitButton
                  :disabled="disabledRowBotton"
                  :model="getItems(slotProps)"
                  class="p-button-text mb-2 action-splitbutton"
                  appendTo="body"
                />
              </div> 
              <!-- <div class="flex mr-2" v-if="!isOfflineDoc(slotProps.data)">
                <Button
                  v-if="
                    !$rest.esTipoUsuario(
                      ['Oficial Sumariante', 'Deposito Judicial', 'Juez', 'Ayudante de Juez'].concat(prosecutorUserTypes),
                      user
                    ) && $offline.onlineMode
                  "
                  v-tooltip.left="'Reasignar secretario'"
                  icon="pi pi-user-plus"
                  class="
                    p-button-rounded p-button-outlined p-button-seccess
                    mr-2
                  "
                  @click="
                    (e) =>
                      assignSecretary(
                        e,
                        slotProps.data.ActuacionUsuarioDependencia.find(
                          (e) =>
                            (e.UsuarioID === 'Sin Usuario Asignado' &&
                              !e.Fechahasta) ||
                            !e.Fechahasta
                        ),
                        slotProps.data
                      )
                  "
                  id="reasignar-secretario"
                  :test-id="'reasignar-secretario' + slotProps.data.ActuacionID"
                />
              </div> -->
            </div>
            <div v-else class="flex">
              <Button v-tooltip.left="'Vincular con esta Actuacion'" icon="pi pi-paperclip"
                class="p-button-rounded p-button-outlined p-button-help"
                :test-id="'vincular-act' + slotProps.data.ActuacionID" @click="vincularActuacion(slotProps.data)" />
            </div>
          </div>
        </template>
      </Column>
      <template #footer v-if="$offline.onlineMode">
        <div class="flex justify-content-between">
          <div class="flex align-items-center">
            <span class="pagination">{{
              `Mostrando registros del ${pagination.offset} al ${
              pagination.offset + pagination.limit > total
              ? total
              : pagination.offset + pagination.limit
              } de un total de ${total}`
              }}</span>
          </div>
          <div class="align-self-center" :style="{ marginRight: '20%' }">
            <ul class="flex flex-row">
              <Button icon="pi pi-angle-double-left" class="p-button-text" :disabled="pagination.actualPage <= 0"
                @click="anterior('x2')" />
              <Button icon="pi pi-angle-left" class="p-button-text" :disabled="pagination.actualPage <= 0"
                @click="anterior()" />
              <li v-for="(e, i) in pagination.indices" v-bind:key="i"
                :style="{ textDecoration: 'none', listStyle: 'none' }">
                <Button @click="goToPage(e - 1)" :style="
                    pagination.actualPage === e - 1
                      ? { backgroundColor: '#2196F3' }
                      : {
                          backgroundColor: 'transparent',
                          color: '#2196F3',
                          border: 'none',
                        }
                  ">{{ e }}</Button>
              </li>
              <Button icon="pi pi-angle-right" iconPos="right" class="p-button-text" test-id="nextButton" :disabled="
                  pagination.actualPage + 1 >= Math.ceil(total / pagination.limit)
                " @click="siguiente()" />
              <Button icon="pi pi-angle-double-right" iconPos="right" class="p-button-text" test-id="nextButton"
                :disabled="
                  pagination.actualPage + 1 >= Math.ceil(total / pagination.limit)
                " @click="siguiente('x2')" />
            </ul>
          </div>
          <div>
            <button disabled :style="{
                backgroundColor: 'transparent',
                cursor: 'normal',
                border: 'none',
              }"></button>
          </div>
        </div>
      </template>
    </DataTable>
    <div>
      <SplitButton v-if="$offline.onlineMode && splitItems.length > 0" label="Descargar excel" id="bottonXLS"
        :model="splitItems" />
    </div>
    <Dialog :visible.sync="displayBasic" :style="{
        width: '30vw',
        height: '300px',
        backgroundClip: 'padding-box',
        backgroundColor: 'white',
      }" contentStyle="padding:0px"><template #header>
        <h4 :style="{ marginTop: '0px', marginBottom: '0px' }">
          Agregar un secretario a cargo
        </h4>
      </template>
      <div v-if="displayBasic">
        <form class="form-secretario">
          <div>
            <Dropdown id="dropdownActuaciones" v-model="dependenciaModal" :options="dependenciaList" filter
              :optionValue="'id'" optionLabel="dependencia" placeholder="Filtrar por dependencia...." :style="{
                position: 'absolute',
                left: '10%',
                top: '30%',
                minWidth: '80%',
              }" v-if="
                $rest.esTipoUsuario(['Super User', 'User Tester'], user) ||
                user.userdata.usuarioNombre == 'SuperUserE3'
              " test-id="dropdownNewSecretarioDependency" />
            <Dropdown id="dropdownActuaciones" v-model="secretario" :options="
                completeNullLabelsAndAddingID(
                  getActuacionesUserByStationId(dependenciaModal),
                  'apellido_nombre'
                )
              " filter optionLabel="apellido_nombre" placeholder="Nombre del Oficial..." :style="{
                position: 'absolute',
                left: '10%',
                top: '50%',
                minWidth: '80%',
              }" dataKey="id" test-id="dropdownNewSecretario">
              <template #item="slotProps">
                <div class="p-dropdown-car-value">
                  <div class="ml-2">{{ slotProps.item.nombre }}</div>
                </div>
              </template>
            </Dropdown>
          </div>
          <div class="field col" :style="{ paddingTop: '150px' }">
            <Button class="m-b-1" @click="AsignarSecretario" :disabled="disabledAsignarButton" test-id="buttonAsignar">
              Asignar
            </Button>
          </div>
        </form>
      </div>
    </Dialog>
    <Dialog :visible.sync="dataExcel.showModal">
      <template #header>
        <h4 :style="{ marginTop: '0px', marginBottom: '0px' }">Descargar Excel</h4>
      </template>
      <div class="flex flex-column align-items-center justify-content-center">
        <ProgressSpinner v-if="dataExcel.loading" />
        <div v-else>
          <Button icon="pi pi-check" class="p-button-rounded p-button-outlined p-button p-component p-button-icon-only"
            :style="{
              color: 'green',
              borderRadius: '100%',
              border: 'solid 1px green',
            }" v-if="dataExcel.data.length > 0"></Button>
          <span v-else-if="!dataExcel.error">No hay sumarios para exportar</span>
        </div>
        <span v-if="dataExcel.loading && !dataExcel.error">El boton se habilitara una vez que se termine de cargar el
          excel...</span>
        <span v-if="dataExcel.error">{{ dataExcel.error }}</span>
      </div>
      <template #footer>
        <div class="flex justify-content-center">
          <JsonExcel :data="dataExcel.data" :fields="dataExcel.fields" default-value="----"
            :name="dataExcel.name + '.xls'" :disabled="dataExcel.loading || dataExcel.data.length == 0">
            <!-- <Button type="button" :disabled="dataExcel.loading ||  dataExcel.data.length==0" > -->
            <div :class="
                dataExcel.loading || dataExcel.data.length == 0
                  ? 'bg-blue-200 border-round p-3 select-none text-white'
                  : 'bg-primary p-3 cursor-pointer border-round select-none'
              ">
              Descargar {{ dataExcel.name }}
            </div>
            <!-- </Button> -->
          </JsonExcel>
        </div>
      </template>
    </Dialog>
    <ModalForm :Show="showModalForm" @sendInfo="addNsumario" Title="Ingrese un Nro° de Sumario  " :Inputs="Inputs"
      :OptionDisable="true" />
    <ModalTemplate title="Confirmación" type="confirmativo" message="¿El hecho fue esclarecido?" :showIcon="true"
      @cancel="closeModalTemplate('ModalRefConfirm')" @confirm="confirm('ModalRefConfirm')" :dismissableMask="true"
      severity="primary" ref="ModalRefConfirm" />
    <ModalTemplate title="Información" type="informativo"
      message="Debe cargar al menos 3 diligencias para finalizar la actuación" :showIcon="true"
      @confirm="closeModalTemplate('ModalRefInform')" severity="danger" ref="ModalRefInform" />
      <ModalTemplate title="Confirmación" type="confirmativo" message="¿Está seguro que desea Eliminar esta Actuación?" :showIcon="true" :dismissableMask="true"
      severity="danger" ref="ModalRefConfirmDelete" />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import axios from "axios";
import auth from "../../mixins/auth.js";
import SplitButton from "primevue/splitbutton";
import JsonExcel from "vue-json-excel";
import { sumariosToExcel2 } from "@/mixins/sumariosToExcelV2.js";
import { sumariosToExcel } from "@/mixins/sumariosToExcel.js";
import prosecutorMixin from "../../components/Dashboard/mixins/prosecutorMixin.js";
import store from "../../store/index.js";
import { actuacionesConfig } from "@/router/RoutesConfig/actuacionesConfig/actuacionesConfig";
import ModalTemplate from "../../components/ModalTemplate.vue";

import TTableFilters from "@/components/TTable/TTableFilters.vue";

import actuacionesFilters from "@/mixins/actuaciones/actuacionesFilters.js";
import actuacionesOrder from "@/mixins/actuaciones/actuacionesOrder.js";
import tanstackFunctions from "../../mixins/tanstackFunctions.js";
import commonMethods from "../../mixins/commonMethods.js";

// import { useQuery } from '@tanstack/vue-query';

export default {
  name: "SumarioHomePage",
  mixins: [auth, sumariosToExcel,sumariosToExcel2, actuacionesFilters, actuacionesOrder, prosecutorMixin, tanstackFunctions,commonMethods],
  components: { SplitButton, JsonExcel, TTableFilters, ModalTemplate },
  data() {
    return {
      ClickAceptar: () => {},
      aceptarLabel: undefined,
      cancelarLabel: undefined,
      CategoriaUfiID: this.$store.state.user.userdata.CategoriaUfiID,
      iconModal: {},
      buttomAceptar: { buttom: true, action: true },
      buttomCancelar: { buttom: true, action: false },
      msjEnable: false,
      mensaje: "",
      showModalForm: { show: false, ActuacionID: "" },
      permisosPorUsuario: {
        //UserAdmin
        "Usuario Admin": [
          "Ingresar Actuacion",
          "Editar Actuacion",
          "Agregar Diligencia",
          "Reasignar Secretario",
          "Finalizar Actuacion",
          "Eliminar Actuacion",
        ],
        "Super User": [
          "Ingresar Actuacion",
          "Editar Actuacion",
          "Agregar Diligencia",
          "Reasignar Secretario",
          "Finalizar Actuacion",
          "Eliminar Actuacion",
        ],
        "User Tester": [
          "Ingresar Actuacion",
          "Editar Actuacion",
          "Agregar Diligencia",
          "Reasignar Secretario",
          "Finalizar Actuacion",
          "Eliminar Actuacion",
        ],
        //UserDependencia
        "Jefe de Sumario": [
          "Ingresar Actuacion",
          "Editar Actuacion",
          "Agregar Diligencia",
          "Reasignar Secretario",
          "Finalizar Actuacion",
          "Eliminar Actuacion",
        ],
        "Jefe de Dependencia": [
          "Ingresar Actuacion",
          "Editar Actuacion",
          "Agregar Diligencia",
          "Reasignar Secretario",
          "Finalizar Actuacion",
          "Eliminar Actuacion",
        ],
        //oficial Sumariante
        "Oficial Sumariante": [
          "Ingresar Actuacion",
          "Editar Actuacion",
          "Agregar Diligencia",
          "Finalizar Actuacion",
        ],

        //UserFiscal
        "Fiscal General": [
          "Ingresar Actuacion",
          "Editar Actuacion",
          "Agregar Diligencia",
          "Finalizar Actuacion",
        ],
        Supervisor: [
          "Ingresar Actuacion",
          "Editar Actuacion",
          "Agregar Diligencia",
          "Finalizar Actuacion",
        ],
        "Ayudante Fiscal": [
          "Ingresar Actuacion",
          "Editar Actuacion",
          "Agregar Diligencia",
          "Finalizar Actuacion",
        ],
        UsuarioFiscal: [
          "Ingresar Actuacion",
          "Editar Actuacion",
          "Agregar Diligencia",
          "Finalizar Actuacion",
        ],
        Fiscal: [
          "Ingresar Actuacion",
          "Editar Actuacion",
          "Agregar Diligencia",
          "Finalizar Actuacion",
        ],
        Administrativo: [
          "Ingresar Actuacion",
          "Editar Actuacion",
          "Agregar Diligencia",
          "Finalizar Actuacion",
        ],
        "Policia Investigador": [
          "Ingresar Actuacion",
          "Editar Actuacion",
          "Agregar Diligencia",
          "Finalizar Actuacion",
        ],

        //UserJudicial
        "Ayudante de Juez": ["Ingresar Actuacion"],
        Juez: ["Ingresar Actuacion"],

        //UserData
        "Control de Gestion": [
          "Ingresar Actuacion",
          "Agregar Diligencia",
          "Reasignar Secretario",
        ],
        "Dirección de Información para el Delito D-2": [
          "Ingresar Actuacion",
          "Agregar Diligencia",
          "Reasignar Secretario",
        ],
        "Dpto. Asuntos Judiciales D-5": [
          "Ingresar Actuacion",
          "Agregar Diligencia",
          "Reasignar Secretario",
        ],
        "Dpto. Planeamiento": [
          "Ingresar Actuacion",
          "Agregar Diligencia",
          "Reasignar Secretario",
        ],
        "Dpto.Criminalistica": [
          "Ingresar Actuacion",
          "Agregar Diligencia",
          "Reasignar Secretario",
        ],
        "Lab. Informatico Forense": [
          "Ingresar Actuacion",
          "Agregar Diligencia",
          "Reasignar Secretario",
        ],

        "Deposito Judicial": ["Ingresar Actuacion"],
        UsuarioJuzgado: ["Ingresar Actuacion"],
      },
      acciones: {
        "Ingresar Actuacion": {
          label: "Ingresar Actuacion",
          icon: "pi pi-external-link",
          command: (slotProps) => {
            this.ingresarActuacion(slotProps); // Pasamos los datos necesarios
          },
        },
        "Editar Actuacion": {
          label: "Editar Actuacion",
          icon: "pi pi-pencil",
          command: (slotProps) => {
            this.editarActuacion(slotProps);
          },
        },
        "Agregar Diligencia": {
          label: "Agregar Diligencia",
          icon: "pi pi-plus-circle",
          command: (slotProps) => {
            this.agregarDiligencia(slotProps);
          },
        },
        "Reasignar Secretario": {
          label: "Reasignar Secretario",
          icon: "pi pi-user-plus",
          command: (slotProps) => {
            const actuacionUsuarioDependencia = slotProps.data.ActuacionUsuarioDependencia.find(
              (e) =>
                (e.UsuarioID === "Sin Usuario Asignado" && !e.Fechahasta) || !e.Fechahasta
            );

            if (actuacionUsuarioDependencia) {
              // Llamamos directamente a assignSecretary pasando los valores correctos
              this.assignSecretary(actuacionUsuarioDependencia, slotProps.data);
            } else {
              console.warn(
                "No se encontró ningún Usuario asignado o Fechahasta no está definida"
              );
            }
          },
        },
        "Finalizar Actuacion": {
          label: "Finalizar Actuacion",
          icon: "pi pi-check-square",
          command: (slotProps) => {
            this.finalizarActuacion(slotProps);
          },
        },
        "Eliminar Actuacion": {
          label: "Eliminar Actuacion",
          icon: "pi pi-trash",
          command: (slotProps) => {
            this.deleteActuacion(slotProps);
          },
        },
      },
      items: [],
      Inputs: [
        {
          type: "input",
          name: "NumeroSumario",
          typeI: "number",
          label: "Numero de sumario",
        },
        {
          type: "dropdown",
          options: [
            {
              value: new Date().getFullYear(),
              name: JSON.stringify(new Date().getFullYear()).substr(-2),
            },
            {
              value: new Date().getFullYear() - 1,
              name: JSON.stringify(new Date().getFullYear() - 1).substr(-2),
            },
          ],
          name: "year",
          typeI: "number",
          placeholder: "Año",
          label: "Año",
          valueDrop: {},
        },
      ],
      splitItems: [],
      disabledRowBotton: false,
      actualDep: "",
      showCalendar: true,
      secretario: "",
      ActuacionUsuarioDependencia: "",
      depModalSelected: null,
      displayBasic: false,
      filters: {},
      isFiltered: false,
      // loading: false,
      forms: [
        {
          name: "Sumario Prevencional por denuncia",
          link: "/sumario/denuncia/formulario",
        },
      ],
      // denunciations: null,
      customName: [],
      data: null,
      customColumn: [],
      //aqui especificamos las columnas que queremos mostrar
      keyShow: [
        { title: "Fecha de Creación", fieldName: "DenunciaFechacreacion" },
        { title: "N° de Actuacion", fieldName: "Numero" },
        { title: "Actuaciones", fieldName: "TiposumarioID" },
        { title: "Denunciante", fieldName: "nombreDenunciante" },
        { title: "Caratula/Causa", fieldName: "CausaCaratula" },
        { title: "Juzgado", fieldName: "nombreJuzgado" },
        { title: "Dependencia", fieldName: "Dependencia" },
        { title: "Estado", fieldName: "EstadoSumario" },
      ],
      // search: "",
      // filterField: null,

      filterActuaciones: [
        { name: "Expedientes", value: "Expediente" },
        { name: "Sumarios", value: "Sumario" },
        // { name: "Expediente Contravencional por oficio", value: "Expediente Contravencional por oficio" },
        // { name: "Expediente Contravencional por denuncia", value: "Expediente Contravencional por denuncia" },
        // { name: "Sumario Prevencional Por Denuncia", value: "Sumario Prevencional Por Denuncia" },
        // { name: "Sumario Prevencional Por Oficio", value: "Sumario Prevencional Por Oficio" },
      ],
      filterEstados: [
        { name: "En curso", value: "En Curso" },
        { name: "Vencida", value: "Vencida" },
        { name: "Finalizada", value: "Finalizada" },
      ],
      filterDependencias: [{ name: "hoy", value: "" }],
      actuacionesOfOptions: [
        { id: 0, option: "Mis registros" },
        { id: 1, option: "Dependencia Registros" },
      ],
      actuacionesOf: { id: 1, option: "Dependencia Registros" },
      disabledAsignarButton: true,
      denunciationsToShow: []
    };
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => { 
      if(from?.path?.includes("/actuaciones/view/")){
        await vm?.$store?.dispatch("sumarioList/get"); 
        vm.denunciationsToShow = vm?.denunciations;
      }
    })
  },
  watch: {
    secretario(newValue) {
      if (newValue !== "") {
        this.disabledAsignarButton = false;
      } else {
        this.disabledAsignarButton = true;
      }
    },
  },
  computed: {
    ...mapGetters({
      getActuacionesUserByStationId: "user/getActuacionesUserByStationId",
      oficialesList: "sumarioList/getOficiales",
      oficialesListModal: "sumarioList/getOficialesModal",
      dependenciaList: "siis/getDependecias",
      denunciations: "sumarioList/getList",
      total: "sumarioList/getTotal",
      pagination: "sumarioList/getPagination",
    }),
    ...mapState({
      sumario: (state) => state.sumario,
      tablasListadas: (state) => state.sumarioList.tablasListadas,
      contPag: (state) => state.sumarioList.contPag,
      formType: (state) => state.sumario.formType,
      loading: (state) => state.sumarioList.loading,
      // search: (state) => state.sumarioList.search,
      // denunciations: (state) => JSON.parse(JSON.stringify(state.sumarioList.list)),
      // search:  state => state.sumarioList.search
      user: (state) => state.user,
      viewData: (state) => state.sumarioViews,
    }),
    lastUrl: {
      get() {
        return this.$store.state.sumarioList.lastUrl;
      },
      set(value) {
        return this.$store.dispatch("sumarioList/setLastUrl", value);
      },
    },
    dependenciaModal: {
      get() {
        //si se selecciono una dependencia entonces devolvemos el ID de la misma
        if (this.depModalSelected) {
          return this.depModalSelected;
        } else {
          //en caso de que no se seleccione ninguno (por ej si este no tiene permiso de ver el drop dependencia), retornamos el ID de su dependencia
          return this.user.userdata.dependenciaID;
        }
      },
      set(value) {
        this.depModalSelected = value;
        // console.log(value, this.depOption)
        // this.$store.dispatch("sumarioList/setOficiales",{oficiales:this.depOption,oficialesModal:value})
      },
    },
    depOption: {
      get() {
        return this.$store.state.sumarioList.depSelected;
      },
      async set(value) {
        await this.$store.dispatch("sumarioList/setDepSelected", value);
        // return this.$store.dispatch("sumarioList/setOficiales", {oficiales:value,oficialesModal:this.depModalSelected});
      },
    },
    rangoFecha: {
      get() {
        return this.$store.state.sumarioList.rangoFechas;
      },
      async set(value) {
        return await this.$store.dispatch("sumarioList/setRangoFecha", value);
      },
    },
    resumen: {
      get() {
        return this.$store.getters["sumario/dataResumen"];
      },
    },
    filterField: {
      get() {
        return this.$store.state.sumarioList.filterField;
      },
      set(v) {
        //Vaciamos search cada vez que se selecciona un filter, para que no mantenga el valor anterior
        if (
          v == "ConUsuarioAsignado=true&exp_pre_EstadoActuacion@@TipoEstadoActuacionID"
        ) {
          this.isFiltered = true;
        } else {
          this.isFiltered = true;
        }
        return this.$store.dispatch("sumarioList/setFilterField", v);
      },
    },
    dataActuacionesOf: {
      get() {
        // console.log(this.actuacionesOf)
        return this.actuacionesOf;
      },
      async set(v) {
        // console.log("entró a dataActuacionesOf", v)
        if (v === "Mis registros") {
          this.actuacionesOf = { id: 0, option: "Mis registros" };
          await this.$store.dispatch("sumarioList/updateUserId", this.user.uid);
        } else {
          this.actuacionesOf = { id: 1, option: "Dependencia Registros" };
          await this.$store.dispatch("sumarioList/updateUserId", "");
        }
        // console.log(this.actuacionesOf)
        await this.getData();
        return this.actuacionesOf;
      },
    },
    filterDropdownOptions: {
      get() {
        let options = [
          { name: "Expedientes", value: "Expediente" },
          { name: "Sumarios", value: "Sumario" },
          { name: "UFI", value: "UNIDAD" },
          { name: "Actuaciones Preliminares - TODAS", value: "Actuacion Preliminar" },
          {
            name: "UFI Delitos Especiales - TODOS",
            value: "Unidades Fiscales de Investigacion D.E",
          },
        ];
        let filterName = "";
        for (const actuationName in this.viewData) {
          filterName = Object.keys(this.viewData[actuationName]?.filters || {})[0];
          options.push({
            name: filterName,
            value: this.viewData[actuationName]?.filters?.[filterName]?.actuacionesFilters
              ?.value,
          });
        }
        options = options.filter(
          (obj) => !Object.values(obj).some((value) => value === undefined)
        );
        if (this.user.userdata.claseDeUsuario === "UsuarioFiscal")
          return this.generateFilterOptionsForFiscalUser(options);
        return options;
      },
    },
  },
  // ↓ Descomentar estas líneas para habilitar Tanstack (todo el created())

  // async created() {
  //   await this.getActuationsForTanstackByPage(2);
  //   await this.changeThePageWithTanstack(0);
  // },
  async mounted() {
    this.$store.dispatch("siis/setDependencias");
    if (this.$rest.esTipoUsuario(["Oficial Sumariante"], this.$store.state.user)) {
      // console.log('"sumarioList/updateUserId"')
      await this.$store.dispatch("sumarioList/updateUserId", this.user.uid);
      this.dataActuacionesOf = "Mis registros";
      
      await this.getData();
      // ↑ Comentar esta línea de arriba para habilitar Tanstack

      // ↓ Descomentar esta línea para habilitar Tanstack
      // await this.getDenunciationsForTanstack();
    } else if (
      !this.$rest.esTipoUsuario(
        [
          "Dpto. Asuntos Judiciales D-5",
          "Dpto.Criminalistica",
          "Lab. Informatico Forense",
        ],
        this.$store.state.user
      )
    ) {
      await this.getData();
       // ↑ Comentar esta línea de arriba para habilitar Tanstack

      // ↓ Descomentar esta línea para habilitar Tanstack
      // await this.getDenunciationsForTanstack();
      if (this.$offline.onlineMode) {
        if (
          !this.$rest.esTipoUsuario(
            ["Deposito Judicial", "Juez", "Ayudante de Juez"],
            this.user
          )
        ) {
          this.$rest.toast_open({
            message: "Cargando los sumarios sin propietario actual...",
            position: "top-right",
            type: "warning",
            duration: 3000,
          });
          // console.log('sumarioList/setSumariosSinSecretario')
          this.$store
            .dispatch(
              "sumarioList/setSumariosSinSecretario",
              this.user.userdata.dependenciaID
            )
            .then(() => { })
            .catch((e) => {
              console.error(e);
              this.$rest.toast_open({
                message: "No se pudieron cargar los sumarios sin secretario actual",
                position: "top-right",
                type: "error",
                duration: 3000,
              });
            });
        }
      }
    } else {
      await this.getData();
      // ↑ Comentar esta línea de arriba para habilitar Tanstack

      // ↓ Descomentar esta línea para habilitar Tanstack
      // await this.getDenunciationsForTanstack();
    }
    this.setXLSDropdown();
    this.filterDropdown[
      "exp_pre_TipoActuacion.TipoActuacionID"
    ].options = this.filterDropdownOptions;
  },
  methods: {
    getItems(slotProps) {
      const tipoUsuario = this.user.userdata.tipoUsuario;
      const estadoActuacion = slotProps.data.EstadoSumario;
      const esFinalizada = estadoActuacion === "Finalizada";
      if (this.permisosPorUsuario[tipoUsuario]) {
        return this.permisosPorUsuario[tipoUsuario]
          .filter((accion) => !(accion === "Finalizar Actuacion" && esFinalizada))
          .map((accion) => {
            return {
              ...this.acciones[accion],
              command: () => {
                this.acciones[accion].command(slotProps);
              },
            };
          });
      } else {
        console.warn(`El tipo de usuario ${tipoUsuario} no tiene permisos definidos.`);
        return [];
      }
    },
    finalizarCambioEstado(mensaje, iconModal, msjEnable = false, error = false, routerPush) {
      this.aceptarLabel = undefined;
      this.cancelarLabel = undefined;
      this.buttomCancelar = { buttom: false, action: false };
      this.buttomAceptar = { buttom: true, action: false, routerPush };
      this.iconModal = iconModal;
      this.err = error;
      this.mensaje = mensaje;
      this.msjEnable = msjEnable;
    },

    async confirm() {
      let data = {
        NuevoEstado: "Finalizada",
        Esclarecido: true,
      };
      try {
        let request = await axios.put(
          `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/estado-actuacion/${this.sumario.id}`,
          data,
          { withCredentials: true, params: { method: "own" } }
        );
        if (request.data.error === "") {
          let iconModal = {
            type: "pi pi-check-circle",
            style: "font-size: 3em; color: rgb(89, 150, 29);",
          };
          let mensaje = "La Actuacion Se Finalizo con Exito";
          this.finalizarCambioEstado(
            mensaje,
            iconModal,
            true,
            false,
            "/actuaciones/list"
          );

        }
      } catch (e) {
        let iconModal = {
          type: "pi pi-exclamation-circle",
          style: "font-size: 3em; color: red;",
        };
        let mensaje = "La Actuacion No se pudo Finalizar";
        this.finalizarCambioEstado(mensaje, iconModal, true, true);
      }
    },

    ingresarActuacion(data) {
      this.seeActuacion(data.data);
    },
    async editarActuacion(data) {
      this.disabledRowBotton = true;
      await this.$store.dispatch("sumario/setSumario", data.data.ActuacionID);
      this.disabledRowBotton = false;
      this.changeNameInStore(this.formType);
      this.$store.dispatch("editMode/prepareActuationForEdition", this.sumario);
      this.$store.dispatch("sumario/setEditMode", true);
      this.$router.push("/actuaciones/edit/" + this.actuacionType + "/initial");
    },
    async agregarDiligencia(data) {
      this.disabledRowBotton = true;
      await this.$store.dispatch("sumario/setSumario", data.data.ActuacionID);
      this.disabledRowBotton = false;
      this.changeNameInStore(this.formType);
      if (this.actuacionType) {
        this.$router.push(
          `/actuaciones/new/${this.actuacionType}/${this.sumario.id}/addDiligencia`
        );
      } else {
        console.log("No se pudo encontrar un pathName para formType:", this.formType);
      }
    },
    reasignarSecretario(data) {
      const actuacionUsuarioDependencia = data.ActuacionUsuarioDependencia.find(
        (e) => (e.UsuarioID === "Sin Usuario Asignado" && !e.Fechahasta) || !e.Fechahasta
      );
      this.disabledRowBotton = true;
      if (actuacionUsuarioDependencia) {
        this.assignSecretary(null, actuacionUsuarioDependencia, data);
      } else {
        console.warn(
          "No se encontró ningún Usuario asignado o Fechahasta no está definida"
        );
      }
      this.disabledRowBotton = false;
    },
    openModalTemplate(type) {
      this.$refs[type].open();
    },
    closeModalTemplate(type) {
      this.$refs[type].close();
    },
    async finalizarActuacion(data) {
      this.disabledRowBotton = true;
      await this.$store.dispatch("sumario/setSumario", data.data.ActuacionID);
      this.disabledRowBotton = false;
      if (this.sumario.diligencia.length >= 3) {
        this.openModalTemplate("ModalRefConfirm");
      } else {
        this.openModalTemplate("ModalRefInform");
      }
    },

    async vincularActuacion(data) {
      const { id } = this.$route.params;
      if (id) this.id = id;
      try {
        // console.log(data.Sumarioufi)
        let objeto = {
          SumarioID: data.SumarioID,
          DepositoArticuloID: this.id,
          NumeroActuacion: data.Numero,
          JuzgadoInterviniente:
            data["Roljuzgadosumario"] != undefined
              ? data.Roljuzgadosumario.Nombre
              : data["Sumarioufi"] != undefined
                ? "UFI N° " + data.Sumarioufi[0].Numero
                : "",
          Caratula:
            data["Sumariocausacaratula"] != undefined
              ? data["Sumariocausacaratula"].map((element) => element.Nombre).toString()
              : data["ActuacionArticulo"] != undefined
                ? data["ActuacionArticulo"]
                  .map((element) => element.Numeroarticulo)
                  .toString()
                : "",
          DependenciaActuacion: data.DependenciaLetra,
          TipoActuacion: data.TiposumarioID,
          EstadoActuacion: data.Estado,
          ActuacionID: data.ActuacionID,
          Fecha: data.FechaCreacion,
        };
        this.$store.commit("sumarioList/loadingUpdate", true);
        // let resp = await axios.post(`${process.env.VUE_APP_BACKEND_ENVIRONMENT}/vinculo-actuacion-deposito/`,objeto,{ withCredentials: true});
        // if(resp.status == 200){
        //   this.$store.commit("sumarioList/loadingUpdate", false);
        //   this.$rest.toast_open({message: `Vinculacion con ${data.Numero} Exitosa!`,position: "top-right",type: "success",duration: 3000,});
        //   // this.$router.push('/deposito/'+this.id)
        // }
        this.$store.dispatch("siis/setVinculatedActuations", objeto);
        this.$store.commit("sumarioList/loadingUpdate", false);
        // Con la siguiente línea de codigo cerramos la ventana
        this.$emit("selectItem");
      } catch (e) {
        console.log(e);
        this.$rest.toast_open({
          message: "ERROR: La vinculacion no se pudo realizar",
          position: "top-right",
          type: "error",
          duration: 3000,
        });
        // this.$router.push('/deposito/'+this.id)
      }
    },

    visibleButtons() {
      if (this.$route.path.includes("deposito")) {
        return false;
      }
      // console.log(this.$route.path)
      return true;
    },
    // async newExcelPlaneamiento(){
    //     try{
    //       this.$store.commit("sumarioList/loadingUpdate", true);
    //       //Traemos la info de todas las actuaciones filtradas y no solo la de las ultimas 8
    //       let dataActuaciones = (await axios.get(`${this.lastUrl.replace('limit=8&offset=0', '') + '&getXLSpersonalizado=' + "Planeamiento"}`, { withCredentials: true})).data.data

    //       let url = process.env.VUE_APP_BACKEND_ENVIRONMENT + '/excel/planeamiento';

    //       let resp = await axios({
    //           method:'POST',
    //           url: url,
    //           data: dataActuaciones,
    //           responseType: 'arraybuffer',
    //           headers: {withCredentials: true}
    //       })

    //       let blob = new Blob([resp.data], { type: 'application/vnd.ms-excel' })
    //       let link = document.createElement('a')
    //       link.href = window.URL.createObjectURL(blob)
    //       link.download = 'Planeamiento.xls'
    //       link.click()
    //       console.log(resp);
    //     }catch(e){
    //       // console.log("ERROR: ",e)
    //       this.$rest.toast_open({
    //         message: "No se pudo cargar el Excel",
    //         position: "top-right",
    //         type: "error",
    //         duration: 3000,
    //       });
    //     }
    //     this.$store.commit("sumarioList/loadingUpdate", false);
    // },
    setXLSDropdown() {
      if (
        this.$rest.esTipoUsuario(
          [
            "Super User",
            "User Tester",
            "Super Usuario de E3",
            "Jefe de Sumario",
            "Jefe de Dependencia",
          ],
          this.$store.state.user
        )
      ) {
        this.splitItems.push({
          label: "Planilla del Delito",
          command: () => {
            // Planilla del delito solia ser "Planeamiento" pero se solicito se cambie, para asi poder ser usada tambien por Asuntos Judiciales D-5
            this.downloadXls2("Planilla del Delito");
          },
        });
        this.splitItems.push(
          {
            label: "Inspección y Control",
            command: () => {
              this.downloadXls("Inspección y Control");
            },
          },
          {
            label: "Estadística de Registros", //se pidio que cambi el nombre, pero el funcionamiento sigui siendo el de Telecomunicaciones d-8
            command: () => {
              this.downloadXls("Estadística de Registros");
            },
          }
        );
      } else if (
        this.$rest.esTipoUsuario(["Control de Gestion"], this.$store.state.user)
      ) {
        this.splitItems.push({
          label: "Inspección y Control",
          command: () => {
            this.downloadXls("Inspección y Control");
          },
        });
      } else {
        this.splitItems.push({
          label: "Planilla del Delito",
          command: () => {
            this.downloadXls2("Planilla del Delito");
          },
        });
      }
    },
    getClass(column, sumario) {
      if (column.title == "Estado") {
        switch (sumario.Estado) {
          case "En Curso":
            return "bg-green-200 min-w-full flex justify-content-center white-space-nowrap p-1 font-medium text-green-800 border-round";
          case "Vencida":
            return "bg-yellow-200 min-w-full flex justify-content-center white-space-nowrap p-1 font-medium text-yellow-800 border-round";
          case "Finalizada":
            return "custom-bg-red-200 min-w-full flex justify-content-center white-space-nowrap p-1 font-medium custom-text-red-800 border-round";
          // return "bg-red-200 min-w-full flex justify-content-center white-space-nowrap p-1 font-medium text-red-800 border-round"
        }
      }
    },
    async goToPage(page) {
      await this.$store.dispatch(
        "sumarioList/updateOffset",
        page * this.pagination.limit
      );
      await this.$store.dispatch("sumarioList/get");
      this.denunciationsToShow = await this.denunciations;
      // ↑ Comentar todas las líneas de arriba hasta la declaración de la función "goToPage(page)" para habilitar Tanstack

      // ↓ Descomentar esta línea para habilitar Tanstack
      // this.changeThePageWithTanstack(page);
    },
    booleans(slotProps, esPara, title) {
      if (esPara == "triangleButton")
        return (
          (!this.isOfflineDoc(slotProps.data) &&
            slotProps.data.ActuacionUsuarioDependencia.find(
              (e) => e.UsuarioID === "Sin Usuario Asignado" && e.Fechahasta === null
            ) &&
            title == "Fecha de Creación") ||
          (!slotProps.data.Numero &&
            this.hasSecretary(slotProps) &&
            title == "Fecha de Creación") ||
          (this.getUsAct(slotProps)?.RecienEnviada == 1 &&
            title == "Fecha de Creación" &&
            this.getUsAct(slotProps).NombreUsuario === this.user.userdata.usuarioNombre)
        );
      if ((esPara = "numeroExtracto"))
        return (
          (!this.isOfflineDoc(slotProps.data) &&
            !slotProps.data.Numero &&
            this.hasSecretary(slotProps)) ||
          (this.getUsAct(slotProps)?.RecienEnviada == 1 &&
            this.getUsAct(slotProps).NombreUsuario === this.user.userdata.usuarioNombre)
        );
    },
    // trae la data del oficial actual (y el ActuacionUsuarioDependencia)
    getUsAct(slotProps) {
      if (this.isOfflineDoc(slotProps.data)) return "Offline";
      return slotProps.data
        ? slotProps.data.ActuacionUsuarioDependencia.find((e) => e.Fechahasta === null)
        : slotProps.ActuacionUsuarioDependencia.find((e) => e.Fechahasta === null);
    },
    async addNsumario(res) {
      if (this.$offline.onlineMode) {
        let numero = res.NumeroSumario + "/" + res.year;
        let sumarioID = this.showModalForm.sumario.ActuacionID;
        let url = `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/sumario/${sumarioID}`;
        try {
          await axios.put(
            url,
            { ...this.showModalForm.sumario, Numero: numero },
            { withCredentials: true, params: { method: "methodCommon" } }
          );
        } catch (error) {
          console.error(error);
          this.$rest.toast_open({
            message: "No se pudo cargar el numero de sumario",
            position: "top-right",
            type: "error",
            duration: 3000,
          });
        }
        // cambio el recien enviado del usuario sumario , esto lo uso para traerlos  al inicio de la bandeja
        let uSumario = this.getUsAct(
          this.$store.state.sumarioList.list.find((e) => e.ActuacionID === sumarioID)
        );
        uSumario["cambioRecienEnviada"] = true;
        uSumario.RecienEnviada = "0";
        try {
          // console.log("DICE: ", ...this.showModalForm.uSumario)

          await axios.put(
            `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/actuacion-usuario-dependencia/${uSumario.ActuacionUsuarioDependenciaID}`,
            uSumario,
            { withCredentials: true, params: { method: "own" } }
          );
          //  cambio el numero en el sumario directamente para no tenes que traerlo de la base de datos y permanece primero para que el usuario lo vea
          this.$store.state.sumarioList.list.find(
            (e) => e.ActuacionID === sumarioID
          ).Numero = numero;
        } catch (error) {
          console.error(error);
        }
      } else {
        this.$rest.toast_open({
          message: "No se puede realizar esta acción sin conexión a internet",
          position: "top-right",
          type: "error",
          duration: 3000,
        });
      }
    },
    async OpenModalAddNsum(sumario) {
      if (this.$offline.onlineMode) {
        sumario = sumario.data;
        let uSumario = this.getUsAct(sumario);
        // este solo cambia el  RecienEnviada si ya tiene numero de sumario
        if (uSumario.RecienEnviada === "1" && sumario.Numero) {
          uSumario["cambioRecienEnviada"] = true;
          uSumario.RecienEnviada = "0";
          try {
            await axios.put(
              `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/actuacion-usuario-dependencia/${uSumario.ActuacionUsuarioDependenciaID}`,
              uSumario,
              { withCredentials: true, params: { method: "own" } }
            );
          } catch (e) {
            console.error(e);
            // this.$rest.toast_open({message: "No se puede realizar esta acción sin conexión a internet", position: 'top-right', type: 'error', duration: 3000,});
          }
          this.$store.state.sumarioList.list
            .find((e) => e.ActuacionID === uSumario.ActuacionID)
            .ActuacionUsuarioDependencia.find(
              (e) => e.Fechahasta === null
            ).RecienEnviada = 0;
        }
        if (!sumario.Numero) {
          this.showModalForm.show = true;
          this.showModalForm.sumario = {
            ActuacionID: sumario.ActuacionID,
            DenunciaID: sumario.DenunciaID,
            DependenciaID: sumario.DependenciaID,
            FechaCreacion: sumario.FechaCreacion,
            TiposumarioID: sumario.TiposumarioID,
          };
        }
      } else {
        this.$rest.toast_open({
          message: "No se puede realizar esta acción sin conexión a internet",
          position: "top-right",
          type: "error",
          duration: 3000,
        });
      }
    },
    hasSecretary(slotProps) {
      return !slotProps.data.ActuacionUsuarioDependencia.find(
        (e) => e.UsuarioID === "Sin Usuario Asignado" && e.Fechahasta === null
      );
    },
    isOfflineDoc(doc) {
      //Cuando genero el excel para planeamiento doc es undefined, por eso se hizo el siguiente if/else
      if (doc?.ActuacionID) {
        // console.log("existe y el id es: ",doc["ActuacionID"] )
        return doc["ActuacionID"].search("offline-") === 0;
      } else {
        // console.log("No existe\n DOC: ",doc);
        return false;
      }
    },

    async AsignarSecretario() {
      this.ActuacionUsuarioDependencia.UsuarioID = this.secretario.UsuarioID;
      this.ActuacionUsuarioDependencia.DependenciaID = this.secretario.DependenciaID;
      if (this.actualDep !== this.ActuacionUsuarioDependencia.DependenciaID) {
        this.ActuacionUsuarioDependencia.CambioDep = 1;
        this.ActuacionUsuarioDependencia["UpdatePrevious"] = {
          DependenciaID: this.actualDep,
        };
      }

      let fechaYhoraActual = new Date();
      let fecha = fechaYhoraActual.toISOString().slice(0, 10).replace("T", " ");
      //separamos la hora y le sumamos +3 horas que se restan por la zona horaria
      let dia = new Date(fechaYhoraActual);
      let hora = dia.getHours() + ":" + dia.getMinutes() + ":" + dia.getSeconds();
      //unimos hora y fecha
      fechaYhoraActual = fecha + " " + hora;

      this.ActuacionUsuarioDependencia.Fechadesde = fechaYhoraActual;

      this.displayBasic = false;
      this.$store.commit("sumarioList/loadingUpdate", true);
      this.ActuacionUsuarioDependencia.RecienEnviada = "1";
      await axios.put(
        `${process.env.VUE_APP_BACKEND_ENVIRONMENT}/actuacion-usuario-dependencia/${this.ActuacionUsuarioDependencia.ActuacionUsuarioDependenciaID}`,
        this.ActuacionUsuarioDependencia,
        { withCredentials: true, params: { method: "own" } }
      );
      this.$store.commit("sumarioList/loadingUpdate", false);
      this.secretario = null;
      await this.getData();
      // this.$router.push(
      //   "/actuaciones/list/" + this.ActuacionUsuarioDependencia.SumarioID
      // );
    },
    async subirActuacion(doc) {
      this.$store.dispatch("sumarioList/loadingUpdate", true);
      await this.$store.dispatch("sumario/setSumario", doc.data.ActuacionID);
      setTimeout(async () => {
        await this.$store.dispatch("sumario/saveDenuncia");
        this.getData();
      }, 300);
      // this.$store.dispatch("sumarioList/loadingUpdate", false);
    },

    fechaMethod(value) {
      let date = new Date();
      let day = date.getDate();
      day = day < 10 ? "0" + day : day;
      let month = date.getMonth() + 1;
      month = month < 10 ? "0" + month : month;
      let year = date.getFullYear();
      if (value === "hoy") {
        value = [year, month, day].join("/");
      }
      if (value === "ultimoMes") {
        if (day <= 30) {
          let diasfaltantes = day - 30;
          month -= 1;
          day = 30 + diasfaltantes;
          month = month < 10 ? "0" + month : month;
        } else {
          day -= 30;
          day = day < 10 ? "0" + day : day;
        }
        value = [year, month, day].join("/");
      }
      if (value === "clear") {
        value = null;
      }
      return this.$store.dispatch("sumarioList/setRangoFecha", value);
    },

    assignSecretary(idUsuarioSum, sumario) {
      this.actualDep = sumario.DependenciaID;
      this.ActuacionUsuarioDependencia = idUsuarioSum;
      this.ActuacionUsuarioDependencia.Numeroextracto = this.ActuacionUsuarioDependencia
        .Numeroextracto
        ? this.ActuacionUsuarioDependencia.Numeroextracto
        : sumario.Numero;
      this.displayBasic = true;
    },

    filterSecretario(event) {
      this.filteredSecretario = this.filteredSecretario.filter(
        (item) => item.nombre.toLowerCase().search(event.query.toLowerCase()) >= 0
      );
    },
    changeNameInStore(formType) {
      const viewConfig = actuacionesConfig.find(
        (config) => config.path === "/actuaciones/view/"
      );
      if (viewConfig && viewConfig.actuaciones) {
        const foundActuacion = viewConfig.actuaciones.find(
          (actuacion) => actuacion.nameInStore === formType
        );

        if (foundActuacion) {
          this.actuacionType = foundActuacion.pathName;
        } else {
          console.log(
            "No se encontró ninguna coincidencia para el formType proporcionado en /actuaciones/view/."
          );
          this.actuacionType = null;
        }
      } else {
        console.log("No se encontró la configuración para /actuaciones/view/.");
      }
    },

    seeActuacion(actuacion) {
      this.$router.push(`/actuaciones/view/${actuacion.ActuacionID}`);
    },

    async deleteActuacion(doc) {
      const confirmed = await this.confirmDelete("ModalRefConfirmDelete");
      if (confirmed) {
        this.$store.dispatch("sumarioList/loadingUpdate", true);
        let resp = await this.$store.dispatch(
          "sumario/deleteActuacion",
          doc.data.ActuacionID
        );

        if (resp === true) {
          this.$store.dispatch("sumarioList/deleteActuaciones", doc.data.ActuacionID);
          this.denunciationsToShow =  this.denunciations;
        }
      }
      this.disabledRowBotton = false;
    },
    
    // async loadActuacion(doc) {
    //   this.$store.dispatch("sumarioList/loadingUpdate", true); // activamos el loading (en este caso se activa desde el store de sumarioList...por ende lo seguimos usando)
    //   await this.$store.dispatch("sumario/setSumario", doc.data.ActuacionID);
    //   this.$store.dispatch("sumarioList/loadingUpdate", false); //De la misma forma que lo activamo, lo desactivamos cuando termina el proceso

    //   this.$router.push(`/sumario/denuncia/detalle/${doc.data.ActuacionID}`);
    // },
    authorizedUser() {
      //si el usuario es el SuperUserE3 que pueda verlos
      let tiposUserAutorizados = [
        "Super Usuario de E3",
        "Super User",
        "User Tester",
        "Jefe de Dependencia",
        "Jefe de Sumario",
      ];
      if (tiposUserAutorizados.includes(this.user.userdata.tipoUsuario)) {
        return true;
      } else {
        return false;
      }
    },
    // async siguiente(mod) {
    //   if (mod == "x2"){
    //     this.$store.dispatch("sumarioList/get", { accion: "siguientex2" });

    //   } else {
    //     this.changeThePageWithTanstack(this.pagination.actualPage + 1);
    //   }
    // },

    // ↑ Descomentar todas las líneas de arriba hasta la declaración de la función "siguiente()" para habilitar Tanstack

    // ↓ Comentar estas líneas, toda la función "siguiente()", para habilitar Tanstack

    siguiente(mod) {
      if (mod == "x2")
        this.$store.dispatch("sumarioList/get", { accion: "siguientex2" });
      else this.$store.dispatch("sumarioList/get", { accion: "siguiente" });
    },
    // anterior(mod) {
    //   if (mod == "x2"){
    //     this.$store.dispatch("sumarioList/get", { accion: "anteriorx2" });
    //   } else {
    //     this.changeThePageWithTanstack(this.pagination.actualPage - 1);
    //   }
    // },

    // ↑ Descomentar todas las líneas de arriba hasta la declaración de la función "anterior()" para habilitar Tanstack

    // ↓ Comentar estas líneas, toda la función "anterior()", para habilitar Tanstack
    
    anterior(mod) {
      if (mod == "x2")
        this.$store.dispatch("sumarioList/get", { accion: "anteriorx2" });
      else this.$store.dispatch("sumarioList/get", { accion: "anterior" });
    },
    async getData(fromSearch) {
      if (this.$offline.onlineMode && fromSearch) {
        let search = await this.$refs.Filters.getFiltersV2();
        await this.$store.dispatch("sumarioList/setSearch", search.search);
      }
      await this.$store.dispatch("sumarioList/get", { accion: "inicio" });
      this.denunciationsToShow =  this.denunciations;
    },

    formatDocOffline(col, data) {
      try {
        switch (col) {
          case "Denunciante":
            var name = "DENUNCIANTE ANONIMO";
            // si existe el denunciante entonces
            if (data.Denunciante.length > 0) {
              // si tenemos el apellido del denunciante
              if (data.Denunciante[0][1].Apellido != "") {
                name = data.Denunciante[0][1].Apellido;
              }
              // si tenemos el nombre del denunciante
              if (data.Denunciante[0][1].Nombre != "") {
                name = name + ", " + data.Denunciante[0][1].Nombre;
              }
            }
            return name;
          case "Fecha de Creación":
            let fechaDelHecho = "";
            if (data.FechaCreacion) {
              return data.FechaCreacion;
            }
            //si tenemos fecha desde y fecha hasta.. incluimos ambas
            if (
              data.Hecho[1].Fechahoradesde != "" &&
              data.Hecho[1].Fechahorahasta != ""
            ) {
              fechaDelHecho =
                data.Hecho[1].Fechahoradesde.slice(0, -8) +
                " / " +
                data.Hecho[1].Fechahorahasta.slice(0, -8);
            }
            //si fechaDelHecho aun no se cargo, vemos cual de las 2 fecha se cargaron
            else {
              if (data.Hecho[1].Fechahoradesde != "") {
                fechaDelHecho = data.Hecho[1].Fechahoradesde.slice(0, -8);
              } else if (data.Hecho[1].Fechahorahasta != "") {
                fechaDelHecho = data.Hecho[1].Fechahorahasta.slice(0, -8);
              }
            }
            return fechaDelHecho;
          case "Actuaciones":
            return data.Sumario.TiposumarioID;
          case "Caratula/Causa":
            //en el caso de que no tenga caratula, es por que se trata de un expediente, el cual cuenta con articulos
            if (data.Sumario.CausaCaratula == "") {
              //como aun dataResumen no fue cargado, tenemos que generar los articulos a mostrar
              let Articulos = "";
              for (let i in data.ActuacionArticulo.ArticulosID) {
                Articulos =
                  Articulos == ""
                    ? data.ActuacionArticulo.ArticulosID[i].Numeroarticulo
                    : Articulos +
                    ", " +
                    data.ActuacionArticulo.ArticulosID[i].Numeroarticulo;
              }
              return Articulos;
            } else return data.Sumario.CausaCaratula;
          case "Juzgado":
            return data.Juzgadointerviniente.Nombre;
          case "N° de Actuacion":
            return data.Sumario.Numero;
          case "Estado":
            return "Offline";
        }
      } catch (e) {
        console.error("formatDocOffline err", col, data, e);
        return "";
      }
    },

    formatDoc(col, data) {
      // console.log("data: ",data)

      try {
        if (!this.$offline.onlineMode || data.ActuacionID.search("offline-") == 0) {
          return this.formatDocOffline(col, data);
        }
        switch (col) {
          case "Denunciante":
            var name = "DENUNCIANTE ANONIMO";
            // si existe el denunciante entonces
            // console.log(data.nombreDenunciante);
            if (data["nombreDenunciante"] != undefined) {
              name = data.nombreDenunciante;
            }
            return name;

          case "Fecha de Creación":
            //Aqui habian puesto la fecha desde y hasta del Hecho, eso se borro, por que este apartado es para fecha de Creacion
            if (data.FechaCreacion != null) {
              return data.FechaCreacion.slice(0, -8);
            }
            return "Sin datos";
          case "Actuaciones":
            const actuationTypeFound = Object.values(store.state.sumarioViews).find(
              (item) => item?.actuationName?.[data.TiposumarioID] !== undefined
            );
            const nombreDelTipoDeActuacion =
              actuationTypeFound !== undefined
                ? actuationTypeFound?.actuationName?.[data.TiposumarioID]
                : data.TiposumarioID;
            return nombreDelTipoDeActuacion;
          // return data.TiposumarioID !== null ? data.TiposumarioID : "Sin datos";
          case "Caratula/Causa":
            //en el caso de que no tenga caratula, es por que se trata de un expediente, el cual cuenta con articulos
            if (data?.CausaCaratula == null || data?.CausaCaratula == "") {
              //como aun dataResumen no fue cargado, tenemos que generar los articulos a mostrar
              // if (data["ActuacionArticulo"] ?  data.ActuacionArticulo.length === 0: true) return "Sin datos";
              if (
                data["ActuacionArticulo"] ? data.ActuacionArticulo.length !== 0 : false
              ) {
                let Articulos = "";
                for (let item of data.ActuacionArticulo) {
                  // console.log('item', item)
                  Articulos =
                    Articulos == ""
                      ? item.Numeroarticulo
                      : Articulos + ", " + item.Numeroarticulo;
                }
                return Articulos;
              }
              if (
                data["Sumariocausacaratula"]
                  ? data.Sumariocausacaratula.length !== 0
                  : false
              ) {
                let MultipleCausaCaratula = "";
                for (let item of data.Sumariocausacaratula) {
                  MultipleCausaCaratula =
                    MultipleCausaCaratula == ""
                      ? item.Nombre
                      : MultipleCausaCaratula + ", " + item.Nombre;
                }
                return MultipleCausaCaratula;
              }
              return "Sin datos";
            } else return data.CausaCaratula;
          case "Juzgado":
            let juzgado =
              data["Roljuzgadosumario"] != undefined
                ? data.Roljuzgadosumario.Nombre
                : data["Sumarioufi"] != undefined
                ? "UFI N°" + data.Sumarioufi[0].Numero
                : "Sin datos";
            // return data.Roljuzgadosumario["Nombre"] != undefined ? data.Roljuzgadosumario.Nombre : 'Sin datos ';
            return juzgado;
          case "N° de Actuacion":
            return data.Numero !== "" ? data.Numero : "Sin datos";
          case "Estado":
            if(data["EstadoSumario"] !== undefined){
              data["EstadoSumario"] = data["Estado"] ? data.Estado : "Sin datos";
              return data.EstadoSumario !== "Sin datos" ? data.EstadoSumario.toUpperCase() : "Sin datos";
            } 
            return data["Estado"] ? data.Estado.toUpperCase() : "Sin datos";
          case "Dependencia":
            return data.DependenciaLetra;
          default:
            // Handle other cases or log an error/return a default value
            console.log(`Unrecognized column: ${col}`);
            return "";
        }
      } catch (e) {
        console.error("formatDoc err", col, data, e);
        return "";
      }
    },
    setStates() {
      this.$store.dispatch("sumario/resetState");

      // this.setRoute('newFormPage')//voy a la pagina de new form page
      this.$router.push("/nueva");
    },
    getCausaCaratula(data) {
      return data.causaCaratula ? data.causaCaratula.nombre : "";
    },

    getformType(data) {
      return data.formType ? data.formType : "";
    },

    getDate(date) {
      const d = new Date(date);
      return d.toLocaleDateString();
    },
    getDendunciante(data) {
      // console.log("getDenunciante: "+JSON.stringify(data) )
      if (data.list.length > 1) {
        return data.list[0].doc.apellido + ", " + data.list[0].doc.nombre;
      } else {
        return "DENUNCIANTE ANONIMO";
      }
    },
    completeNullLabelsAndAddingID(originalArray, labelProp) {
      let arrayCopy = [];
      for (let i = 0; i < originalArray.length; i++) {
        arrayCopy.push({ ...originalArray[i] });
        arrayCopy[i].id = i;
        if (arrayCopy[i][labelProp] === null) {
          arrayCopy[i][labelProp] = "sin datos";
        }
      }
      return arrayCopy;
    },
    showColumnOrNot(columnTitle) {
      let show = true;
      if (columnTitle === "Dependencia") {
        show = this.$rest.esTipoUsuario(
          [
            "Super User",
            "User Tester",
            "Dirección de Información para el Delito D-2",
            "Dpto. Planeamiento",
            "Dpto. Asuntos Judiciales D-5",
            "Dpto.Criminalistica",
            "Lab. Informatico Forense",
            "Control de Gestion",
            "Juez",
            "Ayudante de Juez",
          ],
          this.user
        );
      }
      if (columnTitle === "Juzgado") {
        show = !this.$rest.esTipoUsuario(["Juez", "Ayudante de Juez"], this.user);
      }
      if (columnTitle === "Denunciante") {
        show = !this.$rest.esTipoUsuario(["Super User", "User Tester"], this.user);
      }
      return show;
    },
    generateFilterOptionsForFiscalUser(options) {
      if (this.CategoriaUfiID === "ufis-todas") {
        let onlyUFIs = options.filter((option) => option.name.includes("UFI"));
        return onlyUFIs;
      } else {
        this.filtersFields = this.filtersFields.filter(
          (filter) => filter.name !== "Actuaciones"
        );
        this.$refs.Filters.field = this.filtersFields[0];
        return [];
      }
    },
    async getDenunciationsForTanstack() {

      //  ↓  Descomentar estas líneas para volver a habilitar el Tanstack
      
      //let actuationsFromTanstack = Object.values(JSON.parse(JSON.stringify(this.getActuationsForTanstack())))[0];
      //if(actuationsFromTanstack !== undefined){
      //  this.denunciationsToShow = actuationsFromTanstack;
      //} else {
        await this.$store.dispatch("sumarioList/get");
        this.denunciationsToShow = await this.denunciations;
      //}
    },
    getDenunciationsForTanstackByPage() {
      return this.getActuationsForTanstackByPage(this.pagination.actualPage + 2);
    },
    async changeThePageWithTanstack(page) {
      let actuationsToUpdate = await this.getActuationsCachedByPage(page + 1);
      this.$store.dispatch(
        "sumarioList/updateOffset",
        page * this.pagination.limit
      );
      if(
        await actuationsToUpdate === undefined || (
          page === 0 && Object.values(JSON.parse(JSON.stringify(actuationsToUpdate)))[0] === undefined
        )
      ){
        this.$store.dispatch("sumarioList/get");
        this.denunciationsToShow =  this.denunciations;
        return;
      } else {
        this.denunciationsToShow = page == 0 ? 
        Object.values(JSON.parse(JSON.stringify(actuationsToUpdate)))[0] : JSON.parse(JSON.stringify(actuationsToUpdate))
      }
      await this.getActuationsForTanstackByPage(page + 2);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-secretario {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.p-datatable .p-datatable-tbody>tr>td>#sin-propietario {
  color: #d9534f;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.toolbar>div>* {
  margin-left: 10px;
}

.toolbar>.right {
  display: flex;
  flex-direction: row-reverse;
}

.declaracion {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

.action-buttons {
  text-align: right;
}

.displayNone {
  display: none;
}

.pagination {
  background-color: transparent;
  border: 0 none;
  color: #6c757d;
  margin: 0.143rem;
  padding: 0 0.5rem;
}

.custom-bg-red-200 {
  background-color: #ffada7;
}
.custom-text-red-800 {
  color: #8c231c;
}
.action-buttons {
  display: flex;
  align-items: center;
}
.action-button {
  margin-right: 4px; 
}

.action-splitbutton {

  padding: 0;
  min-width: 2.5rem; 
  height: 2.5rem; 
}


::v-deep .action-splitbutton .p-splitbutton-defaultbutton,
::v-deep .action-splitbutton.p-button-rounded > .p-splitbutton-defaultbutton.p-button,
::v-deep .action-splitbutton.p-button-outlined > .p-splitbutton-defaultbutton.p-button {
  display: none; 
}

</style>
