import { sumarioOficioConfig } from "./sumarioOficioConfig";
import { sumarioDenunciaConfig } from "./sumarioDenunciaConfig";
import { expedienteOficioConfig } from "./expedienteOficioConfig";
import { expedienteDenunciaConfig } from "./expedienteDenunciaConfig";
import { ufiFlagranciaConfig } from "./ufiFlagranciaConfig";
import { ufiAniviConfig } from "./ufiAniviConfig";
import { ufiCavigConfig } from "./ufiCavigConfig";
import { ufiEstablecerConfig } from "./ufiEstablecerConfig";
import { ufiEmergenciasConfig } from "./ufiEmergenciasConfig";
import { ufiSiniestroConfig } from "./ufiSiniestroConfig";
import { ufiSuicidioConfig } from "./ufiSuicidioConfig";
import { ufiIncendioConfig } from "./ufiIncendioConfig";
import { ufiIntoxicacionConfig} from "./ufiIntoxicacionConfig";
import { preliminarIncendioViviendaConfig } from "./preliminarIncendioConfig";
import { preliminarEstablecerConfig } from "./preliminarEstablecerConfig";
import { preliminarFugaConfig } from "./preliminarFugaConfig";
import { preliminarSiniestroConfig } from "./preliminarSiniestroConfig";
import { preliminarCaidaConfig } from "./preliminarCaidaConfig";
import { preliminarIncendioAutoConfig } from "./preliminarIncendioAutoConfig";
import { preliminarTentativaConfig } from "./preliminarTentativaConfig";
import { preliminarOtrosConfig } from "./preliminarOtrosConfig";
import { ufiInformaticosDenunciaConfig } from "./ufiInformaticosDenunciaConfig";
import { ufiGenericaDenunciaConfig } from "./ufiGenericaDenunciaConfig";
import { ufiPropiedadDenunciaConfig } from "./ufiPropiedadDenunciaConfig";
import { ufiInformaticosOficioConfig } from "./ufiInformaticosOficioConfig";
import { ufiGenericaOficioConfig } from "./ufiGenericaOficioConfig";
import { ufiPropiedadOficioConfig } from "./ufiPropiedadOficioConfig";

export const actuacionesConfig = [
    {
      path: "/actuaciones/",
      pathName: "actuaciones",
      titulo: "ACTUACIONES",
      icon: "pi-folder",
      roles: [
        "User Tester",
        "Super User",
        "Jefe de Dependencia",
        "Oficial Sumariante",
        "Jefe de Sumario",
        "UsuarioDependencia",
        "Control de Gestion",
        "Dirección de Información para el Delito D-2",
        "Dpto. Asuntos Judiciales D-5",
        "Dpto. Planeamiento",
        "Ayudante de Juez",
        "Juez",
        'Dpto.Criminalistica',
        'Lab. Informatico Forense',
        'Administrativo',
        'Ayudante Fiscal',
        'Fiscal',
        'Fiscal General',
        'Policia Investigador',
        'Supervisor'
      ],
      type: "button",
      grupo: "principal",
      nameOfRoute : "listadoActuaciones"
    },
    {
      path: "/actuaciones/ufi-delitos-especiales/menu",
      pathName: "ufi-delitos-especiales",
      titulo: "UFI - DELITOS ESPECIALES",
      icon: "pi-flag-fill",
      roles: [
        "User Tester",
        "Super User",
        "Jefe de Dependencia",
        "Oficial Sumariante",
        "Jefe de Sumario",
      ],
      type: "link",
      grupo: "UFI",
      props: { formType: "ufi-delitos-especiales"},
      nameOfRoute : "menu-actuaciones"
    },
    {
      path: "/actuaciones/preliminares/menu",
      pathName: "preliminares",
      titulo: "ACTUACIONES PRELIMINARES",
      icon: "pi-microsoft",
      roles: [
        "User Tester",
        "Super User",
        "Jefe de Dependencia",
        "Oficial Sumariante",
        "Jefe de Sumario",
      ],
      type: "link",
      grupo: "PRELIMINARES",
      props: { formType: "actuaciones-preliminares" },
      nameOfRoute : "menu-actuaciones"
    },
    {
      path: "/final",
      pathName: "final",
      roles: [
        "User Tester",
        "Super User",
        "Jefe de Dependencia",
        "Oficial Sumariante",
        "Jefe de Sumario",
      ],
      titulo: "DATOS LEGALES",
      nameOfRoute : "actuacion-final"
    },
    {
      path: "/actuaciones/newForm",
      pathName: "new-form",
      roles: [
        "User Tester",
        "Super User",
        "Jefe de Dependencia",
        "Oficial Sumariante",
        "Jefe de Sumario",
      ],
      titulo: "ACTUACIONES",
      nameOfRoute : "new-form"
    },
    {
      path: "/actuaciones/notificaciones",
      pathName: "actuacion-notificaciones",
      roles: [
        "User Tester",
        "Super User",
        "Ayudante de Juez",
        "Juez",
      ],
      titulo: "notificaciones",
      nameOfRoute : "actuacion-notificaciones"
    },
    {
      path: "/actuaciones/view/",
      nameOfRoute:"view-id",
      roles: [
        "User Tester",
        "Super User",
        "Jefe de Dependencia",
        "Oficial Sumariante",
        "Jefe de Sumario",
        "Ayudante de Juez",
        "Juez",
        'Dpto.Criminalistica',
        'Lab. Informatico Forense',
        'Administrativo',
        'Ayudante Fiscal',
        'Fiscal',
        'Fiscal General',
        'Supervisor',
        'Policia Investigador'
      ],
      actuaciones:[
        {
          nameInStore: "Sumario Prevencional Por Denuncia",
          pathName: "sumario-denuncia",
        },
        {
          nameInStore: "Sumario Prevencional Por Oficio",
          pathName: "sumario-oficio",
        },
        {
          nameInStore: "Expediente Contravencional por denuncia",
          pathName: "expediente-denuncia",
        },
        {
          nameInStore: "Expediente Contravencional por oficio",
          pathName: "expediente-oficio",
        },
        {
          nameInStore: "Unidades Fiscales de Investigacion Flagrancia",
          pathName: "ufi-flagrancia",
        },
        {
          nameInStore: "Unidades Fiscales de Investigación Delitos Genéricos por Denuncia",
          pathName: "ufi-generica-denuncia",
        },
        {
          nameInStore: "Unidades Fiscales de Investigación Delitos Contra la Propiedad por Denuncia",
          pathName: "ufi-propiedad-denuncia",
        },
        {
          nameInStore: "Unidades Fiscales de Investigación Delitos Informáticos y Estafas por Denuncia",
          pathName: "ufi-informatica-denuncia",
        },
        {
          nameInStore: "Unidades Fiscales de Investigación Delitos Genéricos por Oficio",
          pathName: "ufi-generica-oficio",
        },
        {
          nameInStore: "Unidades Fiscales de Investigación Delitos Contra la Propiedad por Oficio",
          pathName: "ufi-propiedad-oficio",
        },
        {
          nameInStore: "Unidades Fiscales de Investigación Delitos Informáticos y Estafas por Oficio",
          pathName: "ufi-informatica-oficio",
        },
        {
          nameInStore: "Unidades Fiscales de Investigacion CAVIG",
          pathName: "ufi-cavig",
        },
        {
          nameInStore: "Unidades Fiscales de Investigacion ANIVI",
          pathName: "ufi-anivi",
        },
        // {
        //   nameInStore: "Unidades Fiscales de Investigacion D.E Establecer Paradero",
        //   pathName: "ufi-establecer-paradero",
        // },
        {
          nameInStore: "Unidades Fiscales de Investigacion D.E Emergencias Medicas",
          pathName: "ufi-emergencias-medicas",
        },
        {
          nameInStore: "Unidades Fiscales de Investigacion D.E Siniestro Vial",
          pathName: "ufi-siniestro-vial",
        },
        {
          nameInStore: "Unidades Fiscales de Investigacion D.E Ahorcamiento o Suicidio",
          pathName: "ufi-suicidio",
        },
        {
          nameInStore: "Unidades Fiscales de Investigacion D.E Incendio en Vivienda",
          pathName: "ufi-incendio-vivienda",
        },
        {
          nameInStore: "Unidades Fiscales de Investigacion D.E Intoxicacion",
          pathName: "ufi-intoxicacion",
        },
        {
          nameInStore: "Actuacion Preliminar por Incendio en Vivienda",
          pathName: "preliminares-incendio-vivienda",
        },
        {
          nameInStore: "Actuacion Preliminar para Establecer Procedencias",
          pathName: "preliminares-establecer-procedencia",
        },
        {
          nameInStore: "Actuacion Preliminar por Fuga de Menor",
          pathName: "preliminares-fuga-menor",
        },
        {
          nameInStore: "Actuacion Preliminar por Siniestro Vial",
          pathName: "preliminares-siniestro-vial",
        },
        {
          nameInStore: "Actuacion Preliminar por Caida Casual",
          pathName: "preliminares-caida-casual",
        },
        {
          nameInStore: "Actuacion Preliminar por Incendio de Automotor",
          pathName: "preliminares-incendio-automotor",
        },
        {
          nameInStore: "Actuacion Preliminar por Tentativa de Suicidio",
          pathName: "preliminares-suicidio",
        },
        {
          nameInStore: "Actuacion Preliminar - Otros",
          pathName: "preliminares-otros",
        },
      ],
    },
    ...sumarioOficioConfig,
    ...sumarioDenunciaConfig,
    ...expedienteOficioConfig,
    ...expedienteDenunciaConfig,
    ...ufiFlagranciaConfig,
    ...ufiCavigConfig,
    ...ufiAniviConfig,
    //...ufiEstablecerConfig,
    ...ufiEmergenciasConfig,
    ...ufiSiniestroConfig,
    ...ufiSuicidioConfig,
    ...ufiIncendioConfig,
    ...ufiIntoxicacionConfig,
    ...preliminarIncendioViviendaConfig,
    ...preliminarEstablecerConfig,
    ...preliminarFugaConfig,
    ...preliminarSiniestroConfig,
    ...preliminarCaidaConfig,
    ...preliminarIncendioAutoConfig,
    ...preliminarTentativaConfig,
    ...preliminarOtrosConfig,
    ...ufiInformaticosDenunciaConfig,
    ...ufiGenericaDenunciaConfig,
    ...ufiPropiedadDenunciaConfig,
    ...ufiInformaticosOficioConfig,
    ...ufiGenericaOficioConfig,
    ...ufiPropiedadOficioConfig,

  ];
