<template>
  <div class="col-6">
    <div class="flex field w-full">
      <div class="w-full">
        <label for="vinculo">{{valueName}}</label>
        <InputText
          class="input mt-2"
          type="text"
          :placeholder="valueName"
          v-model="dataValue"
          test-id="inputVinculo"
          @blur="blurInputEvent"
        />
      </div>
      <div class="flex justify-content-end ml-auto w-3rem">
        <Button
          icon="pi pi-plus"
          class="p-button-rounded p-button-info p-button-outlined pasteInto plus-button ml-5"
          :disabled="addButtonDisabled"
          v-tooltip.left="tooltip"
          @click="addItemToListboxistBoxOptions"
        />
      </div>
    </div>
    <p class="item-error" v-if="minLengthError">Este campo necesita mínimo {{minLength}} caracteres</p>
    <p class="item-error" v-if="maxLengthError">Este campo no puede tener más de {{maxLength}} caracteres</p>
    <p class="item-error" v-if="itemErrorRepeated">El nuevo item de "{{valueName}}" que intenta ingresar está repetido</p>
    <div>
      <Listbox
        v-model="listboxValue"
        :options="listBoxOptions"
        dataKey="arrId"
        optionValue="arrId"
        optionLabel="name"
      >
        <template #option="slotProps">
          <div v-if="listBoxOptions.length > 0" class="flex">
            <p>{{slotProps.option?.[subPropName] !== undefined ? slotProps.option?.[subPropName] : slotProps.option}}</p>
            <Button
              v-if="slotProps.option !== 'Sin Datos'"
              icon="pi pi-trash"
              class="p-button-danger p-button-lg px-2 py-2 m-1 ml-auto"
              style="z-index: 3"
              v-tooltip.right="'Eliminar Item'"
              @click="deleteItem(slotProps.option)"
            />
          </div>
        </template>
      </Listbox>
    </div>
  </div>
</template>
<script>
import Button from "primevue/button";
import Listbox from 'primevue/listbox';

export default {
  name: "ListBoxOptions",
  components: {
    Button,
    Listbox
  },
  props: [ "Prop_FormPersona_update", "defaultValue", "formDataName", "valueName", "tooltip", "preventRepeatedItems", "minLength",
    "maxLength", "subPropName"
  ],
  data() {
    return {
      listboxValue: '',
      value: '',
      addButtonDisabled: true,
      minLengthError: false,
      maxLengthError: false,
      itemErrorRepeated: false,
      listBoxOptions: ['Sin Datos'],
    };
  },
  mounted(){
    this.listBoxOptions = this.defaultValue;
  },
  watch: {
    listBoxOptions(newValue)  {
      let hasNoDataOption = this.listBoxOptions.some(item => item === 'Sin Datos');
      if(newValue.length === 0) {
        let newlistBoxOptions = ['Sin Datos'];
        this.listBoxOptions = newlistBoxOptions;
      } 
      if(newValue.length > 1 && hasNoDataOption) {
        this.listBoxOptions = this.listBoxOptions.filter(item => item !== 'Sin Datos');
        this.$emit("FormPersona_update", this.formDataName, this.listBoxOptions);
      }
    },
  },
  computed: { 
    dataValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        if(this.listBoxOptions.some(item => item[this.subPropName] === newValue) && this.preventRepeatedItems)  {
          this.addButtonDisabled = true;
          this.itemErrorRepeated = true;
          this.value = newValue; 
          return;
        } else {
          this.addButtonDisabled = false;
          this.itemErrorRepeated = false
        }
        if(newValue.length >= this.minLength && newValue.length <= this.maxLength){
          this.addButtonDisabled = false;
          this.minLengthError = false;
          this.maxLengthError = false;
        }  else {
          this.addButtonDisabled = true;
        }
        this.value = newValue;
      },
    }    
  },
  methods: {
    addItemToListboxistBoxOptions()  {
      let newlistBoxOptions = [...this.listBoxOptions];
      newlistBoxOptions.push(this.value);
      this.listBoxOptions = newlistBoxOptions;
      this.$emit("FormPersona_update", this.formDataName, this.listBoxOptions);
      this.value = '';
      this.addButtonDisabled = true;
    },
    blurInputEvent(){
      this.minLengthError = false;
      this.maxLengthError = false;
      if(this.value.length < this.minLength) this.minLengthError = true;
      if(this.value.length > this.maxLength) this.maxLengthError = true;
      if(this.value.length === 0) this.minLengthError = false;
    },
    deleteItem(IDToDelete){
      this.listBoxOptions = this.listBoxOptions.filter(item => item !== IDToDelete);
      this.$emit("FormPersona_update", this.formDataName, this.listBoxOptions);
    },
  },
};
</script>

<style>
.plus-button {
  margin-top: 27px;
}
.item-error {
  color: #dc3545;
  font-size: 14px;
  margin-top: -5px;
}
</style>
