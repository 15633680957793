<template>
    <div class="Container">
      <p class="inputTitle">Para</p>
      <span class="p-input-icon-left">
        <i class="pi pi-user" />
        <InputText type="text" placeholder="Para" />
      </span>
      <p class="inputTitle asunto">Asunto</p>
      <span class="p-input-icon-left">
        <i class="pi pi-pencil" />
        <InputText type="text" placeholder="Asunto" />
      </span>
      <div class="editor">
        <!-- <p class="inputTitle">Cuerpo</p> -->
        <Editor editorStyle="height: 320px" />
      </div>

      <div class="button-row">
        <Button
          class="p-button-secondary w-10rem  mr-3 mt-3 "
          @click="saveAsDraft()"
          
        >
          <i class="pi pi-pencil mr-2" /> Borrador
        </Button>
        <Button
          class="p-button-warning w-10rem mt-3 "
          @click="cancel()"
          
        >
          <i class="pi pi-times mr-2" /> Descartar
        </Button>
        <Button
          class="p-button-primary w-10rem ml-auto mt-3  "
          @click="sendEmail()"
          
        >
          <i class="pi pi-send mr-2 " /> Enviar Correo
        </Button>
      </div>
    </div>
  </template>
  
  
<script>
import Editor from 'primevue/editor';


    export default {
    components: { Editor},
    name: 'SendEmail',
    props: {
    data: {
    type: Array,
    default: null
    },
  

    },
    data() {
        return{}
    },
    created(){


    },
    methods: {
      sendEmail(){
        alert("correo enviado")
      },
      saveAsDraft(){
        alert("correo guardado como borrador")
      },
      cancel(){
        alert("correo descartado")
      }
    }
    }
</script>


<style scoped>
.Container {
  padding: 50px;
  background-color: white;
}

.p-input-icon-left {
  display: flex;
  align-items: center;
}

.p-input-icon-left input {
  width: 100%;
  box-sizing: border-box;
}

.asunto {
  margin-top: 20px;
}

.inputTitle {
  font-weight: bold;
  margin-bottom: 2px;
}

.editor {
  margin-top: 25px;
}


.button-row {
  display: flex;
  justify-content: space-between; 
  margin-top: 20px;
}

</style>
