
export default {
    methods: {
        //si incluye alguna de las palabras del array retorna true, sino false
        includesWords(array){
            let i = 0;
            while( i < array.length && !this.resumen.formType.includes(array[i])){
            i++;
            }
            return i< array.length ? true : false;
        },
        confirmDelete(modalName) {
            return new Promise((resolve) => {
              this.openModalTemplate(modalName);
      
              const confirmHandler = () => {
                resolve(true);
                this.$refs.ModalRefConfirmDelete.$off("confirm", confirmHandler);
                this.$refs.ModalRefConfirmDelete.$off("cancel", cancelHandler);
                this.closeModalTemplate(modalName);
              };
      
              const cancelHandler = () => {
                resolve(false);
                this.$refs.ModalRefConfirmDelete.$off("confirm", confirmHandler);
                this.$refs.ModalRefConfirmDelete.$off("cancel", cancelHandler);
                this.closeModalTemplate(modalName);
              };
      
              this.$refs.ModalRefConfirmDelete.$on("confirm", confirmHandler);
              this.$refs.ModalRefConfirmDelete.$on("cancel", cancelHandler);
            });
          },
    }
}